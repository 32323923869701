import React from 'react'
import { Routes, Route, Navigate } from 'react-router-dom';
import { ChangePasswordPage, LoginPage, ResetPasswordPage } from './pages';

export default function UnauthenticatedContent() {
    return (
        <React.Fragment>
            <Routes>
                <Route  path='/resetPassword/:token'    element = { <ChangePasswordPage /> } />   
                <Route path='/login'                    element = { <LoginPage /> } />
                <Route path='/reset-password'           element = { <ResetPasswordPage /> } />
                <Route path='*'                         element = { <Navigate to={'/login'} />} />
            </Routes>
        </React.Fragment>
        ); 
    }
    
export const userNavigation = (user) => {
    if (user.isAdmin || user.isSystem || user.isContractor)     return navAdmin;
    else                                                        return navSudo;
} 

const navAdmin = [
    {
        icon: 'home',
        text: 'Home',                   path: '/home',
    },
    {
        icon: 'chart',
        text: 'Reportes',
        items: [ 
            { text: 'Reporte 1',        path: '/rep1' },
            { text: 'Reporte 2',        path: '/rep2' },
        ],
    },
    {
        icon: 'money',
        text: 'Administración',
        items: [ 
            { text: 'Cuentas',          path: '/accounts',  },
            { text: 'Cuotas',           path: '/fees' },
            { text: 'Proveedores',      path: '/suppliers' },
        ],
    },
    {
        icon: 'home',
        text: 'Configuración',    
        items: [ 
            { text: 'Fraccionamientos', path: '/sections' },
            { text: 'Residencias',      path: '/homes' },
            { text: 'Colonos',          path: '/residents' },
            { text: 'Reglamentos',      path: '/agreements' },
        ],
    }, 
    {
        icon: 'preferences',
        text: 'Sistema',    
        items: [ 
            { text: 'Usuarios',         path: '/users' },
            { text: 'Casetas',          path: '/stations' }
        ],
    }, 
];

const navSudo = [
    {
        icon: 'home',
        text: 'Home',                   path: '/home',
    },
    {
        icon: 'home',
        text: 'Visitas',    
        items: [ 
            { text: 'Reporte',          path: '/visits' },
            { text: 'Programadas',      path: '/scheduledVisits' },
        ],
    }, 
    {
        icon: 'home',
        text: 'Residencia',    
        items: [ 
            { text: 'Estado de Cuenta', path: '/cccsections' },
            { text: 'Residencias',      path: '/ccchomes' },
            { text: 'Colonos',          path: '/cccresidents' },
        ],
    }, 

];
import { useEffect, useState } from "react";
import { httpGet } from "../../api/methods";
import './mobilePicture.scss'

const MobilePicture = ({ path, height, width  }) => {
    const [ picture, setPicture ] = useState(null);

    useEffect( () => {
        httpGet('/pictures?path=' + path, { responseType: 'arraybuffer' }).then( res => {
            if (res.isOk) {
                const base64 = btoa(
                    new Uint8Array(res.data).reduce( (data, byte) => data + String.fromCharCode(byte), '' ),
                  );
                  setPicture("data:;base64," + base64);
            }
        })
    }, [ path ] )

    return (
        <div id='picture' style = { { 
                width: (width ?? '100%'), 
                height: (height ?? '100%'), 
                border: "1px solid gray", 
                borderRadius: '5px', 
                padding: '0px' }}>
                    { picture 
                        ? <img src={ picture } alt="Comprobante de Pago" style = { { height: '100%' }} />
                        : <span className='loading'>{ 'Cargando...' }</span>
                    }
        </div>
   )
}
export default MobilePicture 
import React, { useEffect, 
    useRef, 
    useState } from 'react'
import { useParams, useNavigate }   from 'react-router-dom'
import DataGrid, {  Column, 
        HeaderFilter, 
        Pager, 
        Paging } from 'devextreme-react/data-grid';
import { httpGet } from '../../api/methods';
import { DateTime } from 'luxon';
import DatagridHeader from '../../components/datagrid-header/DatagridHeader';
import { timeFormat } from '../../utils/toFormat';
import AmountBox from '../../components/amount-box/AmountBox';
import Payment from '../../components/payment/Payment';
import './feeReport.scss'

const FeeReport = () => {
    const navigate = useNavigate();
    const [fee, setFee] = useState(null)
    const [toApproveFormId, setToApproveFormId] = useState(null);
    const [filterUnpaid, setFilterUnpaid] = useState(true);
    const [startDate, setStartDate] = useState(DateTime.now().startOf('month').toMillis());
    const [endDate, setEndDate] = useState(DateTime.now().endOf('month').toMillis());
    const selectedItem = useRef(null);
    const { id } = useParams();

    useEffect(() => {
        //  Start on first and last day of the month
        if (id) {
            getFeeReport();
        }
    }, [ filterUnpaid, startDate, endDate, toApproveFormId ]);

    const getFeeReport = () => {
        let url = 'fees/' + id + '/report';
        url += filterUnpaid 
            ? '?showUnPaidOnly=1'
            : '?startDate=' + startDate + "&endDate=" + endDate;
        httpGet(url).then( res => {
            if (res.isOk) {
                // let paymentsTo
                setFee(res.data);
            } 
        });
    }
    const onSelectionChangedHandler = (e) => {  selectedItem.current = e.row.data;   }

    const calculateStatusHandler = (e) => {

        // validate
        if (!e.type) return ''; 

        let description = '';
        if (e.type === 'Pago' && e.approved)        description += 'Pago' + (e.paymentId ? "-Tarjeta" : "");
        else if (e.type === 'Pago' && !e.approved)  description += 'Por Aprobar'
        else if (e.isFeePaid && e.approved)         description += 'Pagada'
        else if (e.isFeePaid && !e.approved)        description += 'Pendiente'
        else if (!e.isFeePaid)                      description += 'Pendiente'
        return description;
    }
    const closeHandler = () => {
        navigate('/fees/');
    }
    const onRowPreparedHandler = e => {
        if (e.rowType === 'data' && e.data.numType === 1 && !e.data.isFeePaid )
            e.rowElement.style.color = "red";
    }

    const toApproveHandler = e => {
        if (e.data.type === 'Pago')
            setToApproveFormId(e.data.id);
    }

    const calculatePaymentHandler = (e) => {
        // validate 
        if (!e.type || e.type !== 'Pago') return '';

        //  show intent amount as amount
        if (!e.approved) return (e.wireProofIntent > 0 
                                    ? e.wireProofIntent 
                                    : e.cashIntent );
        return e.amount;
    }

    const statusCellRender = e => {
        //  validate
        if (e.column.caption.toLowerCase() !== 'status')
            return;

        let item = e.data;
        if (item.type === 'Pago')
            return (
                <span className='toApproveLink' onClick = { () => setToApproveFormId(item.id)}>
                    { calculateStatusHandler(e.data)}    
                </span>
            )
        else 
        return (
            <>
                { calculateStatusHandler(e.data)}
            </>
        )
    }

    let titleBanner;
    if (fee) {
        titleBanner = fee.name + ' - ';
        titleBanner += fee.recurrency;       
        titleBanner += ' $' + fee.amount.toFixed(2);
    }

    return (
        fee && <React.Fragment>
            <div className={'content-block'} >
                <div className={'dx-card responsive-paddings'} style={{ position: 'relative', boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)' }}>
                    <DatagridHeader title={ {   banner: titleBanner,
                                                line1: 'Fraccionamiento: ' + fee.section.name,   
                                                line2: filterUnpaid ? "Cuotas Pendientes" : "Reporte de Movimientos",
                                            } }  
                                    imageName='bills'                                 
                                    navs = { [  { icon: 'back',  hint: 'Regresar a Lista de Cuotas', onClick: closeHandler }, 
                                                { dateBox: true, visible: !filterUnpaid, label: 'Desde', defaultValue: startDate, max: endDate,   onValueChanged: (value) => setStartDate(value)  },
                                                { dateBox: true, visible: !filterUnpaid, label: 'Hasta', defaultValue: endDate,   min: startDate, onValueChanged: (value) => setEndDate(value)  },
                                                { caption: filterUnpaid ? 'Movimientos' : 'Pendientes', width: 125, onClick: () => setFilterUnpaid( prev => ( !prev )) },
                                            ] }
                    />
                    <DataGrid 
                            keyExpr={'id'}
                            className={'dx-card wide-card'}
                            dataSource={ fee.homeAccountItems }
                            columnMinWidth={50}
                            columnAutoWidth={true}                       
                            onFocusedRowChanged={ onSelectionChangedHandler }
                            sorting={ { mode: "none" }}
                            onRowPrepared={ e => onRowPreparedHandler(e) }
                            onRowDblClick={ e => toApproveHandler (e) }
                        >
                        <HeaderFilter
                            visible={true} 
                            allowSelectAll={ false }
                            allowSearch={ true }
                            width={350}
                            texts={ {
                                cancel: 'Cancelar',
                                ok: 'Aceptar'
                            }}
                        
                        />

                        <Paging defaultPageSize = { 20 } />
                        <Pager showPageSizeSelector={true} showInfo={true} />

                        <Column dataField={'id'}        visible={false} />

                        <Column dataField={'addDate'}   caption={'Fecha'}       allowFiltering={ false }    width={ 110 }   dataType="date"  
                                                        alignment="right" />

                        <Column                         caption={'Hora'}        allowFiltering={ false }    width={ 110 }   calculateCellValue={ e => timeFormat(e.addDate)}    
                                                        alignment="right" />

                        <Column                         caption={'Status'}      allowFiltering={ false }    width={ 110 }   calculateCellValue={ calculateStatusHandler }       
                                                        alignment="center"                                                  cellRender={ e => statusCellRender(e) }
                                                        visible={!filterUnpaid || fee.paymentsToApprove > 0} />

                        <Column dataField={'home.info'} caption={'Residencia'}  allowFiltering={ true } />

                        <Column dataField={'payment'}   caption={'Pago'}        allowFiltering={ false }    width={ 110 }   calculateCellValue={ calculatePaymentHandler }       
                                                        format={{ type: 'currency', precision: 2}}
                                                        alignment="right"  visible={fee.payments > 0 || fee.paymentsToApprove > 0}/>

                        <Column dataField={'charge'}    caption={'Cargo'}       allowFiltering={ false }    width={ 110 }   format={{ type: 'currency', precision: 2}}
                                                        alignment="right"  />
                    </DataGrid>
                    <div className="summary"> 
                        <AmountBox caption='Pendientes:'    currency = { fee.unPaidFeesAmount }     visible = { fee.unPaidFeesAmount > 0 }/>
                        <AmountBox caption='Pagos:'         currency = { fee.payments }             visible = { fee.payments > 0 } /> 
                        <AmountBox caption='Por Aprobar:'   currency = { fee.paymentsToApprove }    visible = { fee.paymentsToApprove > 0 } /> 
                    </div>
                    { toApproveFormId && <Payment id = { toApproveFormId } onHidden={ () => setToApproveFormId(null) }/> }
                </div>
            </div>
        </React.Fragment>
    )
}
export default FeeReport;
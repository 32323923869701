import React, { useEffect, useRef, useState } from 'react'
import Form, { SimpleItem, GroupItem, ButtonItem } from 'devextreme-react/form';
import { httpDelete, httpGet, httpPost } from '../../api/methods';
import MyPopup from '../popup/Popup';
import MobilePicture from '../mobile-picture/MobilePicture';

import PaymentRejection from './PaymentReject';

import './payment.scss';

const Payment = ({ id, onHidden }) => {
    const [formData, setFormData] = useState({});
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [validationError, setValidationError] = useState(null);
    
    useEffect( () => {
        httpGet('homeAccountItems/' + id).then( res => {
            if (res.isOk) {
                console.log(res.data);
                let item = res.data;
                if (item.approved) {
                    if (item.wireProofIntent > 0.001 )  item.caption = 'Envío de Comprobante de Pago';
                    else if (item.cashIntent > 0.001 )  item.caption = 'Pago Efectivo';
                    else if (item.card )            item.caption = 'Pago con Tarjeta';
                    else                            item.caption = '?';
                } else {
                    if (item.wireProofIntent > 0.001 )  item.caption = 'Aprobación de Comprobante de Pago';
                    else if (item.cashIntent > 0.001 )  item.caption = 'Aprobación de Pago en Efectivo';
                    else                            item.caption = '?';
                }

                //  amount
                if (!item.approved)
                    item.amount = item.wireProofIntent + item.cashIntent;

                //  show Picture
                if (item.wireProofIntent > 0.001)
                    item.showPicture = true;

                setFormData(item);
            }
        } )
    }, [ id ] );
    const rejectHandler = ({ confirmed }) => {
        if (!confirmed) {
            setShowDeleteConfirmation(true);
            return;
        }

        //  Delete account
        httpDelete("/fees/" + id).then( res => {
            if (res.isOk)
                onHidden();
            else
                console.log(res);
        })
    }    
    const acceptHandler = () => {
        const { approved } = formData;
        if (!approved) {
            httpPost('/payments/confirmPaymentIntent/' + id).then( res => {
                if (res.isOk) {
                    if (onHidden)
                        onHidden();
                } else {
                    setValidationError(res.message);
                    let setTimeErrorId = setTimeout(() => {
                        setValidationError(null);
                        clearTimeout(setTimeErrorId);
                    }, 3000);
                }
            })
        }
        else {
            if (onHidden)
            onHidden();
        }
    }

    const acceptButtonOptions = {
        text: !formData.approved ? 'Aprobar Pago' : 'Aceptar',
        type: 'default',
        useSubmitBehavior: false,  
        onClick: () => acceptHandler()
    }
    const deleteButtonOptions = {
        text: !formData.approved ? 'Rechazar' : 'Cancelar',
        visible: !formData.card,
        type: 'danger',
        useSubmitBehavior: false,   
        onClick: () => rejectHandler({ confirmed: false })
    }
    return (
        <>
            <MyPopup caption = { formData.caption } visible={ true } onHidden={ onHidden } width={ formData.showPicture ? 800 : 400 } >  
                <Form 
                    formData = { formData }
                    labelLocation = 'top'
                    labelMode = 'floating'
                    >

                    <GroupItem colCount={ formData.showPicture ? 2 : 1} >
                        { formData.showPicture && 
                            <SimpleItem  colSpan={1} >
                                <MobilePicture path={ 'wireProof/' + id + '.jpeg' }  height={ 350 } width={ 350 }/>
                            </SimpleItem>    
                        }

                        <GroupItem  colSpan={1} >
                            { formData.fee && 
                                <GroupItem colCount={2} >
                                    <SimpleItem dataField='fee.addDate'    label= { { text: "Fecha" } } colSpan={1}
                                            editorType="dxDateBox"
                                            editorOptions={ { readOnly: true, displayFormat: "MMM d, yyyy" } }
                                    />                                
                                    <SimpleItem dataField='fee.recurrency' label= { { text: "Recurrente" } } colSpan={1}
                                            editorOptions={ { readOnly: true } }
                                    />                                
                                    <SimpleItem dataField='fee.name' label= { { text: "Cuota" } } colSpan={2}
                                            editorOptions={ { readOnly: true } }
                                    />                                
                                    <SimpleItem dataField='home.info' label= { { text: "Residencia" } } colSpan={2}
                                            editorOptions={ { readOnly: true } }
                                    />       
                                    <SimpleItem colSpan={ 2 }>
                                        <hr/>
                                    </SimpleItem>                         
                                </GroupItem>
                            }

                            <GroupItem colCount={ 3 }>
                                { formData.resident && 
                                    <SimpleItem dataField='resident.fullName' label= { { text: "Colono" } } colSpan={3}
                                            editorOptions={ { readOnly: true } }
                                    />                                
                                }
                                <SimpleItem dataField='addDate'         label= { { text: !formData.card ? "Envió" : "Pago Tarjeta" } } colSpan={2}
                                        editorType="dxDateBox"
                                        editorOptions={ { readOnly: true, displayFormat: "MMM d, yyyy hh:mm aa" } }
                                />                                
                                <SimpleItem dataField='amount' label= { { text: "Cantidad" } } colSpan={1}
                                        editorOptions={ { readOnly: true, format: { type: 'currency', precision: 2 } } }
                                />                                
                                <SimpleItem dataField='description' label= { { text: "Información" } } colSpan={3}
                                        editorOptions={ { readOnly: true, visible: formData.card  } }
                                />                                
                            </GroupItem>

                        </GroupItem>    
                    </GroupItem>    

                    <GroupItem colCount={2}>
                        <SimpleItem colSpan={2}> <hr style = {{ margin: '0px', height: '1px', backgroundColor: '#ccc', border: 'none' }} /> </SimpleItem>
                        <ButtonItem horizontalAlignment='left'  buttonOptions={ deleteButtonOptions } colSpan={1}/>
                        <ButtonItem horizontalAlignment='right' buttonOptions={ acceptButtonOptions } colSpan={1} />
                    </GroupItem>

                </Form>
                {   validationError && <p className = 'apiError'> { validationError  } </p>  }
                {   showDeleteConfirmation && <PaymentRejection payment = { formData } onHidden={ () => setShowDeleteConfirmation(false) } />}
            </MyPopup>
        </>    
    )
}
export default Payment;
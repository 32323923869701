import React, { useEffect, useRef, useState } from 'react'
import { ToolbarItem } from 'devextreme-react/popup';
import Form, { SimpleItem, GroupItem, ButtonItem, TabbedItem, TabPanelOptions, Tab } from 'devextreme-react/form';
import { httpDelete, httpGet, httpPost, httpPut } from '../../api/methods';
import MyPopup from '../../components/popup/Popup';
import './stations.scss';
import { CheckBox, TagBox } from 'devextreme-react';

const StationForm = ({ id, onHidden }) => {
    const [formData, setFormData] = useState({});
    const [selectedCamera, setSelectedCamera] = useState('Driver');
    const [validationError, setValidationError] = useState(null);
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

    useEffect( () => {
        if (id === 'new') {
            setFormData({
            });
        } else {
            httpGet('/stations/' + id).then( res => {
                let station = res.data;
                setFormData({
                    name: station.name,
                    description: station.description,
                    computerName: station.computerName,

                    cameraDriverIp:  station.cameraDriverIp,
                    cameraDriverModel:  station.cameraDriverModel,
                    cameraDriverUsername:  station.cameraDriverUsername,
                    cameraDriverPassword:  station.cameraDriverPassword,
                    
                    cameraIDIp:  station.cameraIDIp,
                    cameraIDModel:  station.cameraIDModel,
                    cameraIDUsername:  station.cameraIDUsername,
                    cameraIDPassword:  station.cameraIDPassword,

                    cameraVehicleIp:  station.cameraVehicleIp,
                    cameraVehicleModel:  station.cameraVehicleModel,
                    cameraVehicleUsername:  station.cameraVehicleUsername,
                    cameraVehiclePassword:  station.cameraVehiclePassword,

                    cameraExitIp:  station.cameraExitIp,
                    cameraExitModel:  station.cameraExitModel,
                    cameraExitUsername:  station.cameraExitUsername,
                    cameraExitPassword:  station.cameraExitPassword,

                    cameraResidentIdIp:  station.cameraResidentIdIp,
                    cameraResidentIdModel:  station.cameraResidentIdModel,
                    cameraResidentIdUsername:  station.cameraResidentIdUsername,
                    cameraResidentIdPassword:  station.cameraResidentIdPassword,

                    cameraResidentOutIp:  station.cameraResidentOutIp,
                    cameraResidentOutModel:  station.cameraResidentOutModel,
                    cameraResidentOutUsername:  station.cameraResidentOutUsername,
                    cameraResidentOutPassword:  station.cameraResidentOutPassword,

                });       
            }).catch( e => console.log(e) );
        }
    }, [ ] );

    const submitHandler = (e) => {
        e.preventDefault();
        //  Validate
        saveStation().then( res => {
            if (res.isOk){ 
                closeForm();
            }
            else            
                setValidationError(res.message);
        }).catch( e => console.log(e) );
    }

    const saveStation = () => {
        return new Promise((resolve, reject) => {
            let body = { ...formData }
            delete body.computerName;

            if (id === 'new') {
                //  posting new
                httpPost('stations/', body).then( res => {
                    resolve(res);
                }).catch( e => console.log(e) );
            } else {
                //  updating
                httpPut('stations/' + id, body).then( res => {
                    resolve(res);
                }).catch( e => console.log(e) );
            }    
        })
    }
    const onHidingHandler = () => { closeForm(); }
    const closeForm = () => { if (onHidden) onHidden(); }
    const deleteHandler = ({ confirmed }) => {
        if (!confirmed) {
            setShowDeleteConfirmation(true);
            return;
        }
        //  Delete
        httpDelete('stations/' + id).then( res => {
            if (res.isOk)   onHidingHandler();
            else            console.log(res);
        })
    }
    const acceptButtonOptions = {
        text: id === 'new'  
              ? 'Agregar'
              : 'Actualizar',
        type: 'default',
        useSubmitBehavior: true,  
    }
    const deleteButtonOptions = {
        text: 'Eliminar',
        type: 'danger-outline',
        useSubmitBehavior: false,   
        onClick: () => deleteHandler({ confirmed: false })
    }
    let caption = id === 'new' 
                    ? 'Agregar Caseta' 
                    : 'Editar Caseta: ' + formData.name;


    const cameraOptions = {
        items:  [   { id: "Driver",         text: "Conductor" },
                    { id: "ID",             text: "Identificación" },
                    { id: "Vehicle",        text: "Vehículo" },
                    { id: "Exit",           text: "Salida" },
                    { id: "ResidentId",     text: "Entrada Colono" },
                    { id: "ResidentOut",    text: "Salida Colono" },
                ],
        value: selectedCamera,
        placeholder: 'Seleccionar. . .',
        valueExpr: 'id',
        displayExpr: 'text',
        onValueChanged: ({ value }) => { setSelectedCamera(prev => value) }  
    }                    

    return (
        <>
            <MyPopup caption = { caption } visible={ true } onHidden={ onHidden } width={ 800 }  >  
                <form onSubmit={ submitHandler }>
                    <Form 
                        formData = { formData }
                        labelLocation='top'
                        labelMode='floating'
                        >

                        <GroupItem colCount={3} >
                            <GroupItem colSpan={2} colCount={12} caption='Información'>
                                <SimpleItem dataField='name'            label={ { text: 'Nombre de Caseta' } }  colSpan={ 6 } isRequired={ true } />
                                <SimpleItem dataField='computerName'    label={ { text: 'Clave - PC' } }        colSpan={ 6 }  />
                                <SimpleItem dataField='description'     label={ { text: 'Description' } }       colSpan={ 12 } />
                            </GroupItem>

                            <GroupItem colSpan={1} caption='IP Camaras' >

                                <SimpleItem label={ { text: "Función de Cámara" } } colSpan={1} 
                                            editorType="dxSelectBox"
                                            editorOptions= { cameraOptions }
                                />

                                <SimpleItem dataField={"camera" + selectedCamera + 'Ip'}        label={ { text: "Dirección IP" } }  />
                                <SimpleItem dataField={"camera" + selectedCamera + 'Model'}     label={ { text: "Modelo" } }  />
                                <SimpleItem dataField={"camera" + selectedCamera + 'Username'}  label={ { text: "Username" } }  />
                                <SimpleItem dataField={"camera" + selectedCamera + 'Password'}  label={ { text: "Contraseña" } }  />


                                {/* <TabbedItem>
                                    <TabPanelOptions deferRendering={false} height={140}  />
                                    <Tab title = 'Conductor' colCount={2}>
                                        <SimpleItem>

                                        </SimpleItem>
                                    </Tab>

                                    <Tab title = 'Identificación' colCount={2}>
                                        <SimpleItem>

                                        </SimpleItem>
                                    </Tab>

                                    <Tab title = 'Vehículo' colCount={2}>
                                        <SimpleItem>

                                        </SimpleItem>
                                    </Tab>

                                    <Tab title = 'Salida' colCount={2}>
                                        <SimpleItem>

                                        </SimpleItem>
                                    </Tab>

                                    <Tab title = 'Colono Entrada' colCount={2}>
                                        <SimpleItem>

                                        </SimpleItem>
                                    </Tab>

                                    <Tab title = 'Colono Salida' colCount={2}>
                                        <SimpleItem>

                                        </SimpleItem>
                                    </Tab>
                                </TabbedItem> */}
                                
                            </GroupItem>
                        </GroupItem>

                        <GroupItem colCount={2}>
                            {   id === 'new'
                                ?   <SimpleItem colSpan={1} />
                                :   <ButtonItem horizontalAlignment='left' buttonOptions={ deleteButtonOptions } colSpan={1}/>
                            }
                            <ButtonItem horizontalAlignment='right' buttonOptions={ acceptButtonOptions }   colSpan={1} />
                        </GroupItem>

                    </Form>
                    {   validationError && <p className = 'apiError'> { validationError  } </p>  }
                </form>
            </MyPopup>
            <MyPopup   
                caption='Confirmar'
                visible = { showDeleteConfirmation }
                onHidden={ () => setShowDeleteConfirmation(false) }
                width = { 'auto' }       
                height={ 'auto' }
                >
                    <p>Desea eliminar usuario ?</p> 
                <ToolbarItem 
                    widget = 'dxButton'
                    toolbar = 'bottom'
                    location = 'center'
                    options = { 
                        {
                            text: 'Aceptar',
                            onClick: () => deleteHandler({ confirmed: true })
                        }
                    }
                />
            </MyPopup>
        </>    
    )
}
export default StationForm;
import notify from 'devextreme/ui/notify';

export const myNotify = (message, type) => {
     notify(
          {    
               message,
               type,
               shading: true,
               width: "auto",
               maxWidth: 600,
               displayTime: type === 'error' ? 5000 : 3000,
               animation: {   
                    show: {   
                         type: "fade",
                         duration: 2500,
                         from: 0,
                         to: 1
                         },
                    hide: {   type: "fade",
                         duration: 1500,
                         to: 0
                    }
               }
          }, {
               direction: "up-push",
               position: "bottom center"
          },
     );
}
import { currency as formatCurrency } from "../../utils/toFormat";
import './amountBox.scss';

const AmountBox = ({ caption, currency, value, width, height, visible = true }) => {

     return (
          visible && <div className='amountBox' style = { { width: width ?? 175, height: height ?? 45 }}  >
               
               <span className='caption'>
                    { caption }
               </span>

               <span className='amount' style = {{ display: currency ? null : 'none' }} >
                    { formatCurrency( { value: currency }) }  
               </span> 

               <span className='amount' style = {{ display: value ? null : 'none' }} >
                    { value }  
               </span> 
          </div>
     )
}
export default AmountBox;
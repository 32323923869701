import React, { useState, useEffect, useRef } from 'react'
import DataGrid, {
     Column,
     Pager,
     Paging, 
     Editing, 
     Texts,
     MasterDetail
} from 'devextreme-react/data-grid';
import ScheduledVisitDetail from './ScheduledVisitDetail'
import { httpGet } from '../../api/methods'
import { getStore } from '../../api/stores';

function Visits() {

     const cellHomeRenderHandler = e => {
          let info = "";
          //   Home
          let h = e.data.home;
          info = h.section.name;
          info += ": [ " + h.number + " ] " + h.street;
          return info;
     }

     // const cellInformationRenderHandler = e => {
     //      let info = "";
     //      //   Vehicle Visit          
     //      if (e.data.numType === 100) {
     //           let v = e.data;
     //           info = v.vehicle.brand.name;
     //           info += " [" + v.vehicle.plate.plateNumber + " ]";
     //      }
     //      return info;
     // }


     const newRowHandler = e => {
          console.log(e);
     }

     return (

          <React.Fragment>
               <div className={'content-block'}>
                   <div className={'dx-card responsive-paddings'} style={{ position: 'relative' }}>
                       <div style = { { position: "relative" } }>
                           <h4 style={{ position: 'absolute', top: -3, left: 20, zIndex: 1 } } >Visitas Programadas</h4>
                    </div>
                    <DataGrid 
                         className={'dx-card wide-card'}
                         dataSource={ getStore('visits', '/scheduledByResident') }
                         onDataErrorOccurred={ e => { e.error.message = e.error.response.data.message }}
                         onInitNewRow={ newRowHandler }
                         columnAutoWidth = { true }
                         >
                         <Paging defaultPageSize = { 10 } />
                         <Pager showPageSizeSelector={true} showInfo={true} />
                         <Editing
                              mode={ "batch" }
                              startEditAction={ 'dblClick' }
                              allowAdding = { true }
                              allowUpdating =  { true }
                              allowDeleting =  { true }
                              confirmDelete={ true }
                         >
                         <Texts
                              addRow = 'Programar Visita'
                              cancelAllChanges = 'Ignorar cambios'
                              cancelRowChanges = 'Igonorar cambios en la linea'
                              confirmDeleteMessage = 'Esta seguro de eliminar Visita Programada ? '
                              confirmDeleteTitle = 'Eliminar Visita Programada'
                              deleteRow= 'Eliminar'
                              editRow = 'Editar'
                              saveAllChanges = 'Aplicar los cambios'
                              saveRowChanges = 'Aplicar'
                              undeleteRow = 'Cancelar Eliminar'
                              validationCancelChanges= 'Cancelar Cambios'
                              />
                         </Editing>
                         <Column dataField={'id'}        
                              visible={false}  
                              width={150} />
                         <Column dataField={'homeId'}    
                              caption={'Home Id'}  
                              visible={false} 
                              hidingPriority={8} />
                         <Column dataField={'scheduleDate'}
                              caption={'Fecha'}
                              dataType={'datetime'}
                              width={250}
                              hidingPriority={7}
                              minWidth={250}
                              alignment="center"
                                   >

                         </Column>
                         <Column dataField={''}
                              caption={'Residencia'}
                              cellRender={ cellHomeRenderHandler }
                              hidingPriority={6}  />

                         <MasterDetail
                              enabled = {true}
                              render = { e => <ScheduledVisitDetail visit = { e.data } /> }
                         />
                    </DataGrid>   
                    </div>
               </div>
          </React.Fragment>  
     )
}
export default Visits;
import {    HomePage, 
            AccountsPage,   AccountPage,       
            SuppliersPage,  SupplierReportPage,     
            FeesPage,       FeeReportPage,
            Agreements,
            Sections, 
            HomesPage,
            ApprovalsPage,
            ResidentsPage,
            UsersPage,
            StationsPage,
            VisitsPage,
            ScheduledVisitsPage
    } from './pages';

import { withNavigationWatcher } from './contexts/navigation';

const routes = [ 
    { path: '/home',                    element: HomePage }, 

    { path: '/fees',                    element: FeesPage },        
    { path: '/fees/:id',                element: FeeReportPage }, 
    { path: '/accounts',                element: AccountsPage },    
    { path: '/accounts/:id',            element: AccountPage }, 
    { path: '/suppliers',               element: SuppliersPage },   
    { path: '/suppliers/:id',           element: SupplierReportPage }, 
    
    { path: '/sections',                element: Sections },
    { path: '/homes',                   element: HomesPage },
    { path: '/residents',               element: ResidentsPage },
    { path: '/agreements',              element: Agreements }, 

    { path: '/users',                   element: UsersPage },
    { path: '/stations',                element: StationsPage },   

    { path: '/visits',                  element: VisitsPage },   
    { path: '/scheduledVisits',         element: ScheduledVisitsPage },   
];

export default routes.map(route => {
    return {
        ...route,
        element: withNavigationWatcher(route.element, route.path)
    };
});

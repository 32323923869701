import 'devextreme/dist/css/dx.common.css';
import './themes/generated/theme.base.css';
import './themes/generated/theme.additional.css';
import './dx-styles.scss';
import './dx-global';
import React from 'react';
import { HashRouter as Router } from 'react-router-dom';
import LoadPanel from 'devextreme-react/load-panel';
import { NavigationProvider } from './contexts/navigation';
import { AuthProvider, useAuth } from './contexts/auth';
import { AppInfoProvider } from './contexts/appInfo';
import { useScreenSizeClass } from './utils/media-query';
import Content from './AppContent';
import UnauthenticatedContent from './AppUnauthenticated';

function App() {
  const { user, loading } = useAuth();
  if (loading) return <LoadPanel visible={true} />;
  if (user)    return <Content />;
               return <UnauthenticatedContent />;
}

export default function Root() {
  const screenSizeClass = useScreenSizeClass();
  return (
    <Router>
      <AuthProvider>
        <AppInfoProvider>
          <NavigationProvider>
            <div className={`app ${screenSizeClass}`}>
              <App />
            </div>
          </NavigationProvider>
        </AppInfoProvider>
      </AuthProvider>
    </Router>
  );
}

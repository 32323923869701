import './polyfills';
import './index.css';
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import axios from 'axios';

const apiURL = (url) => {
  url = url.trim(); 
  url = url.replace(':3000', '');     //  This port may be used at development
  //  for developement, it may use these ports 
  if (url.includes('localhost'))
  {
    //  delete last 
    if (url.substring(url.length -1) === '/') url = url.substring(0, url.length - 1 )
    url += url.includes('https://')
          ? ":8443"    //  Https Port
          : ":8080"    //  Http Port
  }
  if (url.includes('www.') && !url.includes('api.')) url = url.replace('www.',  'www.api.'); 
  else                      url = url.replace('//', '//api.');
  
  console.log(url);
  return url; 
}

// Axios configuration
axios.defaults.baseURL = apiURL(window.origin); 
axios.defaults.headers.common['Access-Control-Allow-Origin'] = "*";
axios.defaults.headers.common['Access-Control-Allow-Headers'] = "X-Requested-With";
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
    <App />
  // </React.StrictMode>
);
import React, { useEffect, useState } from 'react'
import './accountStatement.scss';
import { ToolbarItem } from 'devextreme-react/popup';
import Form, { SimpleItem, GroupItem, ButtonItem } from 'devextreme-react/form';
import { httpDelete, httpGet, httpPost, httpPut } from '../../api/methods';
import MyPopup from '../../components/popup/Popup';
import { homeInfo }  from '../../utils/toFormat'
import MobilePicture from '../../components/mobile-picture/MobilePicture';

const AccountItemForm = ({ item, accountId, accountType, onHidden }) => {       
    const [formData, setFormData] = useState(null);
    const [validationError, setValidationError] = useState(null);
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [suppliers, setSuppliers] = useState([]);
    const [homes, setHomes] = useState([]);
    const [fees, setFees] = useState([]);
    const [typeItems, setTypeItems] = useState( [   
        { numType: 11,  text: "Pago Servicio" },
        { numType: 12,  text: "Pago Crédito a Residente" },
        { numType: 13,  text: "Pago (Otro)" },
        { numType: 1,   text: "Deposito: Cuota" },
        { numType: 6,   text: "Deposito Directo" },
    ]);
    useEffect( () => {
        if (item) {
            httpGet("/accounts/items/" + item.id).then( res => {
                let accItem = res.data;
                console.log(item);
                setFormData({
                    date: accItem.date,
                    amount: Math.abs(accItem.amount),
                    supplierId: accItem.supplierId,
                    numType: accItem.numType,
                    reference: accItem.reference,
                    accountId: accItem.accountId,
                    homeId: item.homeAccountItem?.homeId,
                    homeAccountItemId: item.homeAccountItemId,
                });
                if      (accItem.numType === 11) getSuppliers();
                else if (accItem.numType === 12) getHomes(item.accountId);
                else if (accItem.numType ===  1) getFees(item.accountId);
                setTypeItems(prev => ([ ...prev, { numType: accItem.numType, text: accItem.type } ] ) );        //  some of the 
            }).catch( e => console.log(e) );  
        } 
        else {
            httpGet('sections?accountId=' + accountId).then( res => {
                //  options for type of item to create/add
                if (res.isOk) {
                    setFormData( { 
                        accountId,
                        supplierId: null,
                        numType: 11,        // Default Value
                    } );
                }
                getSuppliers();             //  since payments is by default... get the suppliers to be showm
            }).catch( e => console.log(e));
        }
    }, [ ] )

    const submitHandler = (e) => {
        e.preventDefault();

        //  Validate
        saveAccountItem().then( res => {
            if (res.isOk)   closeForm();
            else            setValidationError(res.message)            
        }).catch( e => console.log(e) );
    }

    const saveAccountItem = () => {
        return new Promise((resolve, reject) => {
            if (!item) {
                //  posting new
                httpPost("/accounts/items/", { ...formData }).then( res => {
                    resolve(res);
                }).catch( e => console.log(e) );
            } else {
                //  updating
                httpPut("/accounts/items/" + item.id, { ...formData }).then( res => {
                    resolve(res);
                }).catch( e => console.log(e) );
            }    
        })
    }
    const onHidingHandler = () => { closeForm();   }
    const closeForm = () => { if (onHidden) onHidden();  }

    const deleteHandler = ({ confirmed }) => {
        if (!confirmed && item) {
            setShowDeleteConfirmation(true);
            return;
        }
        //  Delete account
        httpDelete("/accounts/items/" + item.id).then( res => {
            if (res.isOk)   onHidingHandler();
            else            console.log(res);
        })
    }
    const acceptButtonOptions = {
        text: item  
                ? "Actualizar"
                : "Agregar",
        type: 'default',
        useSubmitBehavior: true,  
    }
    const deleteButtonOptions = {
        visible: (item && formData?.numType !== 3 && formData?.numType !== 4 && formData?.numType !== 5),  //  Mobile App Account Items
        text: "Eliminar",
        type: 'danger',
        useSubmitBehavior: false,   
        onClick: () => deleteHandler({ confirmed: false })
    }
	const balanceOptions = {
        readOnly: (formData?.numType === 1 || formData?.numType === 3),
        format: { type: 'currency', precision: 2 }
    }
    const typeOptions = {
        readOnly: item,
        items: typeItems,
        placeholder: 'Seleccionar. . .',
        valueExpr: 'numType',
        displayExpr: 'text',
        defaultValue: formData?.numType,
        onValueChanged: ( {value} ) => { typeChangeHandler(value); }  
    }

    const dateOptions = {
        readOnly: formData?.numType === 3 || formData?.numType === 4 || formData?.numType === 5,           // Mobile App Date cannot be edited
        value: formData?.date ?? Date.now(),
    }

    //  Suppliers
    const suppliersOptions = {
        items:  suppliers,
        placeholder: 'Seleccionar. . .',
        displayExpr: 'name',
        valueExpr: 'id',
    }
    const typeChangeHandler = (value) => {
        if (value === 11)     {
            getSuppliers(); 
        }
        else if (value === 12) {
            getHomes(formData.accountId);
        }
        else if (value === 1) {
            getFees(formData.accountId);
        }
        setFormData(prev => ({ ...prev, numType: value }));
    }
    const getSuppliers = () => {
        //  get the supppliers
        httpGet('suppliers').then(res => {
            setSuppliers(res.data);
        });
    }   
    //  Homes
    const homesOptions = {
        readOnly: !!item,
        items:  homes,
        placeholder: 'Seleccionar residencia',
        displayExpr: 'text',
        valueExpr: 'id',
    }
    const getHomes = (accountId) => {
        //  validate
        if (!accountId) return;
        //  get the homes
        if (item) {
            let home = item.homeAccountItem.home;
            setHomes([ { id: home.id,
                         text: formatHome(home) } ])
        } else {
            httpGet('homes?accountId=' + accountId).then(res => {
                if (res.isOk) {
                    let lookUp = [ ];
                    for (let i = 0; i < res.data.length; i++) {
                        lookUp.push( {  id: res.data[i].id, text: formatHome(res.data[i]) } );
                    }
                    setHomes(lookUp);
                } else
                    setHomes([]);
            });
        }
    }   
    const formatHome = (home) => {
        let info = home.section.name + ": " + home.info;
        return info;
    }
    const feeChangedHandler = (accountItemId) => {
        let fee = fees.find(f => f.accountItemId === accountItemId);
        setFormData(prev => ({ ...prev, amount: Math.abs(fee.item.amount), homeId: fee.item.homeId, feeId: fee.item.feeId } ));
    }
    const feesOptions = {
        readOnly: !!item,
        items: fees,
        placeholder: 'Seleccionar cuota',
        searchExpr: 'text',
        displayExpr: 'text',
        valueExpr: 'accountItemId',
        onValueChanged: ( { value } ) => { feeChangedHandler(value) }  
    }
    const getFees = (accountId) => {
        //  validate
        if (!accountId) return;
        //  get the unpaid fees
        if (item) {
            setFees([ { accountItemId: item.homeAccountItemId, text: 
                        homeInfo(item.homeAccountItem) }]);
        } else {
            let url = accountType === 'Bancaria' 
                      ? 'homeAccountItems?bankAccountId=' + accountId
                      : 'homeAccountItems?cashAccountId=' + accountId
            url += '&numType=1';        //  Fee
            url += '&isFeePaid=0';
            httpGet(url).then( res => {
                if( res.isOk ) {
                    let lookUp = [ ];
                    for (let i = 0; i < res.data.length; i++) {
                        lookUp.push({ accountItemId: res.data[i].id, text: homeInfo(res.data[i]), item: res.data[i] })                        
                    }
                    setFees(lookUp);
                } else
                    setFees([]);
            });
        }
    }
    const referenceOptions = {
        readOnly: (formData?.numType === 3),
    }

    let caption = item 
                    ? "Editar - " + item.type
                    : "Agregar Movimiento de Cuenta"; 
    return (
        formData && <>
            <MyPopup caption = { caption } visible={ true } onHidden={ onHidden }  >  
                <form onSubmit={ submitHandler }>
                    <Form 
                        formData = { formData }
                        labelLocation='top'
                        labelMode='floating'
                        >
                        <GroupItem colCount={3}>
                            <SimpleItem dataField="numType"    label={ { text: "Tipo de Movimiento" } }    colSpan={1}     isRequired={ !item }
                                        editorType="dxSelectBox"
                                        editorOptions= { typeOptions }
                            />
                            <SimpleItem dataField='date'    label= { { text: "Fecha" } }                    colSpan={1}     isRequired={ true } 
                                        editorType="dxDateBox"
                                        editorOptions={dateOptions}
                            />
                        </GroupItem>                       
                        <GroupItem colCount={3} colSpan={3} >
                            {/* Pago: Servicio */}
                            {   formData?.numType === 11 &&
                                <SimpleItem dataField="supplierId"  label={ { text: "Proveedor" } } colSpan={2}  isRequired={ true }
                                            editorType="dxSelectBox"
                                            editorOptions= { suppliersOptions }
                            /> }
                            {/* Pago: Deposito a Residente / Home */}
                            {   formData?.numType === 12 &&
                                <SimpleItem dataField="homeId"  label={ { text: "Casa" } } colSpan={2}  isRequired={ true }
                                            editorType="dxSelectBox"
                                            editorOptions= { homesOptions }
                            /> }
                            {/* Fee Payment - Transference */}
                            {   formData?.numType === 1 &&
                                <SimpleItem dataField="homeAccountItemId"  label={ { text: "Cuota" } } colSpan={2}  isRequired={ true }
                                            editorType="dxSelectBox"
                                            editorOptions= { feesOptions }
                            /> }
                            {/* Pago: Deposit to Residente / Home - Direct Deposit */}
                            {   (formData?.numType === 13 || formData?.numType === 6 ) &&
                                <SimpleItem dataField="reference"  label={ { text: "Referencia / Nota" } }   colSpan={ 2 }
                                            editorType="dxTextBox"
                            /> }

                            <SimpleItem dataField="amount"  label={ { text: "Cantidad" } }   colSpan={ 1 }
                                        editorType="dxNumberBox"
                                        editorOptions={ balanceOptions }
                            />
                        </GroupItem>

                        { formData?.numType !== 13 && formData?.numType !== 6 && 
                            <GroupItem>
                                <SimpleItem dataField="reference"  label={ { text: "Referencia / Nota" } }   colSpan={ 3 }
                                            editorType="dxTextBox"
                                            editorOptions={ referenceOptions }
                                />
                            </GroupItem> 
                        }


                        {   formData?.numType === 4 &&
                                <SimpleItem  colSpan={ 1 } >
                                    <MobilePicture path={ 'wireProof/' + formData.homeAccountItemId + '.jpeg' } width={ 300 } height = { 300 } />                               
                                </SimpleItem>
                        }




                        <GroupItem colCount={2}>
                            <SimpleItem colSpan={2}> <hr style = {{ margin: '0px', height: '1px', backgroundColor: '#ccc', border: 'none' }} /> </SimpleItem>
                            <ButtonItem horizontalAlignment="left"  buttonOptions={ deleteButtonOptions }   colSpan={1}/>
                            <ButtonItem horizontalAlignment="right" buttonOptions={ acceptButtonOptions }   colSpan={1} />
                        </GroupItem>
                    </Form>

                    {   validationError && <p className = "apiError"> { validationError  } </p>  }
                </form>
            </MyPopup>

            <MyPopup   
                caption='Confirmar'
                visible = { showDeleteConfirmation }
                onHidden={ () => setShowDeleteConfirmation(false) }
                width = { "auto" }       
                height={ "auto" }
                >
                    <p>Desea eliminar el Movimiento a Cuenta Bancaria ?</p> 
                <ToolbarItem 
                    widget = "dxButton"
                    toolbar = "bottom"
                    location = "center"
                    options = { 
                        {
                            text: "Aceptar",
                            onClick: () => deleteHandler({ confirmed: true })
                        }
                    }
                />
            </MyPopup>
        </>    
    )
}
export default AccountItemForm;
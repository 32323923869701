import React, { useEffect, useRef, useState } from 'react'
import HtmlEditor, { Toolbar, Item } from 'devextreme-react/html-editor'
import { httpGet, httpPut } from '../../api/methods';

function Editor({ header, getUrl, putUrl, fieldName }) {
     const [showToolbar, setShowToolbar] = useState(false);
     const [contentReady, setContentReady] = useState(false)
     const headerValues = [false, 1, 2, 4];
     const htmlContent = useRef("")
     
     useEffect( () => {
          //  validate
          if (!fieldName) return;
          httpGet(getUrl).then(res => {
               htmlContent.current = res.data[fieldName];
               setContentReady(true);
          })
     }, [])

     const focusInHandler = () => {
          setShowToolbar(true)
     }

     const focusOutHandler = () => {
          setShowToolbar(false)

          // saving 
          if (!putUrl) return;
          httpPut(putUrl, { [fieldName]: htmlContent.current }).then(

          )
     }

     return (
          <div>
               { header && <h4>{ header }</h4>}
               { contentReady && <HtmlEditor
                    defaultValue={ htmlContent.current }
                    onValueChanged={ e => htmlContent.current = e.value }
                    onFocusIn  = { focusInHandler }
                    onFocusOut = { focusOutHandler } >
                    <Toolbar >
                         <Item name="undo"             visible={ showToolbar}/>
                         <Item name="redo"             visible={ showToolbar}/>
                         {/* <Item name="separator"        visible={ showToolbar}/> */}
                         {/* <Item name="header"           visible={ showToolbar}
                               acceptedValues={headerValues} />  */}
                         < Item name="separator"        visible = { showToolbar} />
                         < Item name="bold"             visible = { showToolbar} />
                         < Item name="italic"           visible = { showToolbar} />
                         < Item name="strike"           visible = { showToolbar} />
                         < Item name="underline"        visible = { showToolbar} />
                         < Item name="separator"        visible = { showToolbar} />
                         < Item name="alignLeft"        visible = { showToolbar} />
                         < Item name="alignCenter"      visible = { showToolbar} />
                         < Item name="alignRight"       visible = { showToolbar} />
                         < Item name="alignJustify"     visible = { showToolbar} />
                         < Item name="separator"        visible = { showToolbar} />
                    </Toolbar>
               </HtmlEditor> }
          </div>
     )
}

export default Editor
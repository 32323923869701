import { Routes, Route, Navigate } from 'react-router-dom';
import routes from './app-routes';
import { SideNavOuterToolbar as SideNavBarLayout } from './layouts';
import { Footer } from './components';
import { ChangePasswordPage } from './pages';
import { useAppInfo } from './contexts/appInfo';

export default function Content() {
  const { info } = useAppInfo();
  return (
    <>
      <SideNavBarLayout title = { info?.appName + ': ' + info?.client.name }>
        <Routes>
          {routes.map(({ path, element }) => (
            <Route  key={path}    path={path}   element={element} />
          ))}
          <Route  path='/resetPassword/:token' element = { <ChangePasswordPage /> } />   
          <Route  path='*'                     element = { <Navigate to='/home' />}
          />
        </Routes>
        <Footer>
          © 2022-{new Date().getFullYear()} { " " + info?.appName + ' v:' + info?.version } 
        </Footer>
      </SideNavBarLayout>
    </>
  );
}
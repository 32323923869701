import { DateTime } from "luxon";

let moneyFormat = new Intl.NumberFormat('en-US', {
     style: 'currency',
     currency: 'USD',
 });

export const currency = ({value, showZero = true, showAbsolute = false }) => {
     //   value is zero
     if (!showZero && value < 0.0001 && value > -0.00001) return "";

     let response = "";
     //   show negative sign (before $)
     if (value < 0 && !showAbsolute) response = "-";
     value = Math.abs(value);
     response += moneyFormat.format(value);

     return response;
}

export const homeInfo = ({ section, info }) => {
     let response = '';
     response += section ? (section.name + ": ") : "";
     response += info;
     return response;
}

export const timeFormat = (dateTime) => {
     let dt = DateTime.fromISO(dateTime);    
     return dt.toLocaleString(DateTime.TIME_SIMPLE);
}

import dxDataGrid from "devextreme/ui/data_grid";

//  Datagrid
const customizeColumnsHandler = (columns) => {
    columns.forEach(col => {
        col.color = 'red';
    });
  }

dxDataGrid.defaultOptions({       
    options: {
        // Here go the DataGrid properties
        showBorders:            true,
        defaultFocusedRowIndex: 0,
        columnAutoWidth:        true,
        columnHidingEnabled:    true,
        columnMinWidth:         50,
        showColumnLines:        true,
        rowAlternationEnabled:  true,
        focusedRowEnabled:      true,
        noDataText:             '. . .',
        onRowExpanding:         e => e.component.collapseAll(-1),
        customizeColumns:       e => customizeColumnsHandler(e)
    },
});

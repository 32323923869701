import React, { useRef, useState } 
                            from 'react'
import DataGrid, { Column, Pager, Paging, RequiredRule } 
                            from 'devextreme-react/data-grid';
import { useNavigate }      from 'react-router-dom'
import { getStore }         from '../../api/stores';
import { myNotify}          from '../../ui/myNotify';
import ResidentForm from './ResidentForm';
import DatagridHeader from '../../components/datagrid-header/DatagridHeader';

const Residents = () => {
    const navigate = useNavigate();
    const [editId, setEditId] = useState(null);
    const selectedId = useRef(null);

    const addHandler = () => {  setEditId('new');    }
    const editHandler = () => {
        if (selectedId.current) {
            setEditId(selectedId.current);
            return;
        }
        myNotify('Seleccionar colono para editar', 'error');
    }
    const reportHandler = () => {
        if (selectedId.current)        {
            navigate('/resident/' + selectedId.current)
            return;
        }
        myNotify('Seleccionar colono para ver reporte', 'error');       
    }
    return (
        <React.Fragment>
            <div className={'content-block'} >
                <div className={'dx-card responsive-paddings'} style={{ position: 'relative', boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)' }}>
                    <DatagridHeader title = { { banner: 'Colonos' } }   imageName='residents'
                                    navs = { [   { icon: 'doc',  hint: 'Mostrar Reporte', onClick: reportHandler }, 
                                                    { icon: 'edit', hint: 'Editar Colono',   onClick: editHandler },
                                                    { icon: 'add',  hint: 'Agregar Colono',  onClick: addHandler }
                                                ] }
                    />   
                    <DataGrid 
                            id='datagrid'
                            keyExpr={ 'id' }
                            className={'dx-card wide-card'}
                            dataSource={ getStore('residents') }
                            columnMinWidth={100}
                            columnAutoWidth={true}                       
                            onFocusedRowChanged={ (e) => { selectedId.current = e.row.data.id; } }
                            onRowDblClick={ reportHandler } 
                        >
                        <Paging defaultPageSize = { 10 } />
                        <Pager showPageSizeSelector={true} showInfo={true} />
                        <Column dataField={'id'}        visible={false}  />
                        <Column dataField={'fullName'}   caption={'Nombre'}   />
                        <Column dataField={'email'}      caption={'e-mail'}   />
                        <Column dataField={'phone'}      caption={'Telefono'}  />
                    </DataGrid>
                    { editId &&         <ResidentForm  id = { editId } onHidden={ () => setEditId(null) } />}
                </div>
            </div>
        </React.Fragment>
    )
}

export default Residents;
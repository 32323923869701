import React, { useEffect, useRef, useState } 
                            from 'react'
import DataGrid, { Column, HeaderFilter, Pager, Paging } 
                            from 'devextreme-react/data-grid';
import { useNavigate }      from 'react-router-dom'
import { getStore }         from '../../api/stores';
import { myNotify}          from '../../ui/myNotify';
import FeeForm              from './FeeForm';
import DatagridHeader from '../../components/datagrid-header/DatagridHeader';
import { httpGet } from '../../api/methods';

const Fees = () => {
    const navigate = useNavigate();
    const [ editId, setEditId ] = useState(null);
    const [ fees, setFees ] = useState(null);
    const selectedId = useRef(null);

    useEffect( () => { 
        //  load fees
        loadFees();

    }, [ editId ])
    const loadFees = () => [
        httpGet('fees').then( res => {
            if (res.isOk) {
                setFees(res.data);
            }
        })
    ]

    const addHandler = () => { setEditId("new"); }
    const editHandler = () => {
        if (selectedId.current)        {
            setEditId(selectedId.current);
            return;
        }
        myNotify('Selecciona Cuota para Editar', 'error');       
    }
    const reportHandler = () => {
        if (selectedId.current)        {
            navigate('/fees/' + selectedId.current)
            return;
        }
        myNotify('Seleccionar Cuota para ver Reporte', 'error');       
    }  
    const calculatePendingValueHandler = e => {
        let amount = Math.abs(e.pending);
        if (amount <= 0.001) return '';
        return amount;
    }
    const refreshHandler = () => {
        loadFees();
    }  

    const calculateToApproveValueHandler = e => {
        let amount = Math.abs(e.toApprove ?? 0);
        if (amount <= 0.001) return '';
        return amount;
    }
    return (
        <React.Fragment>
            <div className={'content-block'} >
                <div className={'dx-card responsive-paddings'} style={{ position: 'relative', boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)' }}>
                    <DatagridHeader title = { { banner: 'Cuotas' } }    imageName='bills'
                                    navs = { [  { icon: 'refresh',  hint: 'Actualizar',         onClick: refreshHandler },
                                                { icon: 'doc',      hint: 'Reporte de Cuotas',  onClick: reportHandler },
                                                { icon: 'edit',     hint: 'Editar Cuota',       onClick: editHandler },
                                                { icon: 'add',      hint: 'Agregar Cuota',      onClick: addHandler }
                                    ] }
                    />
                    <DataGrid 
                            keyExpr={ 'id' }
                            className={'dx-card wide-card'}
                            dataSource={ fees }
                            columnMinWidth={100}
                            columnAutoWidth={true}                       
                            onFocusedRowChanged={ (e) => { selectedId.current = e.row.data.id; } }
                            onRowDblClick={ reportHandler } 
                            sorting={ { mode: "none" }}
                        >
                        <HeaderFilter
                            visible={true} 
                            allowSelectAll={ false }
                            allowSearch={ true }
                            width={350}
                            texts={ {
                                cancel: 'Cancelar',
                                ok: 'Aceptar'
                            }}
                            
                        
                        />
                        <Paging defaultPageSize = { 10 } />
                        <Pager showPageSizeSelector={true} showInfo={true} />
                        <Column dataField={'id'}            visible={false} />
                        <Column dataField={'section.name'}  allowFiltering={ true }     caption={'Fraccionamiento'} />
                        <Column dataField={'name'}          allowFiltering={ false }    caption={'Nombre de Cuota'} />
                        <Column dataField={'amount'}        allowFiltering={ false }    caption={'Cantidad'}        
                                                            format={ {type: 'currency', precision: 2,} }    
                                                            alignment={"right"}            
                                                            width={ 150 }/>

                        <Column dataField={'recurrency'}    allowFiltering={ false }    caption={'Tipo'}            width={ 125 } />
                        <Column dataField={'dueDate'}       allowFiltering={ false }    caption={'Fecha (Prox)'}    dataType={"date"}   alignment={"right"}                     width={ 125 } />
                        <Column dataField={'pending'}       allowFiltering={ false }    caption={'Pendiente'}       calculateCellValue={ calculatePendingValueHandler }  
                                                            format={ {type: 'currency', precision: 2,} }    alignment={"right"}
                                                            width={ 150 }/>
                        <Column dataField={'toApprove'}     allowFiltering={ false }    caption={'Por Aprobar'}     calculateCellValue={ calculateToApproveValueHandler }  
                                                            format={ {type: 'currency', precision: 2,} }    alignment={"right"}
                                                            width={ 150 }/>
                    </DataGrid>
                    { editId && <FeeForm id = { editId } onHidden={ () => setEditId(null) } />}
                </div>
            </div>
        </React.Fragment>
    )
}
export default Fees;
import axios from 'axios';
export const signIn = (username, password) => {
    return new Promise( (resolve, reject) => {
        axios.post('auth', { username, password }).then( res => {
            axios.defaults.headers.common["x-auth-token"] = res.data.token;
            localStorage.setItem("userId", res.data.id );
            localStorage.setItem("x-auth-token", res.data.token );
            resolve({
                isOk: true,
                data: res.data
            })
        }).catch(err => {
            resolve({
                isOk: false,
                data: null,
                message: err.response?.data?.message
            })
        });
    });  
}
export const getUser = () => {
    return new Promise( (resolve, reject) => {
        let userId  = localStorage.getItem("userId");
        let token   = localStorage.getItem("x-auth-token");
        axios.defaults.headers.common["x-auth-token"] = token;
        axios.get('users/' + userId).then( res => {
            if (res.data.access)
                resolve({
                    isOk: true,
                    data: res.data
                })
            else             
            resolve({
                isOk: false,
                data: null,
                message: "El usuario no tiene acceso"
            })
        }).catch( err => {
            resolve({
                isOk: false,
                data: null,
                message: err.response?.data?.message
            })
        })
    });  
}
export const signOut = () => {
    localStorage.clear();
}

export const resetPassword = (username) => {
    return new Promise( (resolve, reject) => {
        axios.post('/auth/resetPassword', { username } ).then( res => { 
            resolve({
                isOk: true,
                data: res.data
            });
        }).catch( err => {
            resolve({
                isOk: false,
                data: null,
                message: err.response?.data?.message
            });
        });
    });
}


export const changePassword = (token, password) => {
    return new Promise( (resolve, reject) => {
        axios.post('/auth/newPassword', { token, password } ).then( res => { 
            resolve({
                isOk: true,
                data: res.data
            });
        }).catch( err => {
            resolve({
                isOk: false,
                data: null,
                message: err.response?.data?.message
            });
        });
    });
}

export const getClient = () => {
    return new Promise( (resolve, reject) => {
        axios.get('clientInfo/').then( res => {
            if (res.data)
                resolve({
                    isOk: true,
                    data: res.data
                })
        }).catch( err => {
            resolve({
                isOk: false,
                data: null,
                message: err.response?.data?.message
            })
        })
    });  
}
import React, { useState, useEffect, useRef } from 'react'
import DataGrid, {
     Column,
     Pager,
     Paging, 
     Editing, 
     Texts,
     MasterDetail
} from 'devextreme-react/data-grid';
import ResponsiveBox, { Row, Col, Item, Location, } from 'devextreme-react/responsive-box';
import VisitDetail from './VisitDetail'
import { httpGet } from '../../api/methods'
import DateBox from 'devextreme-react/date-box';

function Visits() {
     const range = useRef({   start: new Date() - (1000 * 60 * 60 * 24 * 7), 
                              end: new Date() * 1});
     const [ visits, setVisits ] = useState(null);
     
     useEffect(() => {
          getResidentVisits();
     }, []);

     const cellHomeRenderHandler = e => {
          let info = "";
          //   Home
          let h = e.data.home;
          info = h.section.name;
          info += ": [ " + h.number + " ] " + h.street;
          return info;
     }

     const cellInformationRenderHandler = e => {
          let info = "";
          //   Vehicle Visit          
          if (e.data.numType === 100) {
               let v = e.data;
               info = v.vehicle.brand.name;
               info += " [" + v.vehicle.plate.plateNumber + " ]";
          }
          return info;
     }

     const onValueChangeStartHandler = (e) => {
          range.current.start = e;
          getResidentVisits();
     }
     const onValueChangeEndHandler = (e) => {
          range.current.end = e;
          getResidentVisits();
     }
     const getResidentVisits = () => {
          let url = "reports/visits";
          url += "?startDate=" + range.current.start;
          url += "&endDate=" + range.current.end;
          url += "&status=all";
          httpGet(url).then( res =>{
               setVisits(res.data);
        });
     }
     const screen = (width) => {
          return (width < 700) ? 'sm' : 'lg';
        }

     return (
          <React.Fragment>
               <div className={'content-block'}>
                    <div className={'dx-card responsive-paddings'} style={{ position: 'relative' }}>

                    <ResponsiveBox
                         singleColumnScreen="sm"
                         screenByWidth={ screen }>

                         <Row ratio={1}></Row>
                         <Row ratio={1}></Row>
                         <Col ratio={2}></Col>
                         <Col ratio={1}></Col>
                         <Col ratio={1}></Col>

                         <Item>
                              <Location row={0}   col={0}   screen="lg"    />
                              <Location row={0}   col={0}   screen="sm"    />
                              <div className="item">
                                   <h4 >Visitas</h4>
                              </div>
                         </Item>

                         <Item>
                              <Location row={0}   col={1}   screen="lg"    />
                              <Location row={1}   col={0}   screen="sm"    />
                              <div className="item">
                                   <DateBox                              
                                        defaultValue = { range.current.start }
                                        onValueChange={ onValueChangeStartHandler }
                                   />
                              </div>
                         </Item>

                         <Item>
                              <Location row={0}   col={2}   screen="lg"    />
                              <Location row={2}   col={0}   screen="sm"    />
                              <div className="item">
                                   <DateBox
                                        defaultValue={ range.current.end }
                                        onValueChange={ onValueChangeEndHandler }
                                   />                    
                              </div>
                         </Item>
                    </ResponsiveBox>

                    <DataGrid 
                         className={'dx-card wide-card'}
                         dataSource={ visits }
                         keyExpr={ 'id' }
                         columnAutoWidth = { true }
                         >
                         <Paging defaultPageSize = { 10 } />
                         <Pager showPageSizeSelector={true} showInfo={true} />
                         <Editing
                              mode={ "batch" }
                              startEditAction={ 'dblClick' }
                              allowAdding={false}
                              allowUpdating={false}
                              allowDeleting={false}
                              confirmDelete={ false }
                         >
                         <Texts
                              addRow = 'Agregar Visita'
                              cancelAllChanges = 'Ignorar cambios'
                              cancelRowChanges = 'Igonorar cambios en la linea'
                              confirmDeleteMessage = 'Esta seguro de eliminar Visita ? '
                              confirmDeleteTitle = 'Eliminar Visita'
                              deleteRow= 'Eliminar'
                              editRow = 'Editar'
                              saveAllChanges = 'Aplicar los cambios'
                              saveRowChanges = 'Aplicar'
                              undeleteRow = 'Cancelar Eliminar'
                              validationCancelChanges= 'Cancelar Cambios'
                              />
                         </Editing>
                         <Column dataField={'id'}        
                              visible={false}  
                              width={150} />
                         <Column dataField={'homeId'}    
                              caption={'Home Id'}  
                              visible={false} 
                              hidingPriority={8} />
                         <Column dataField={'inDate'}
                              caption={'Entrada'}
                              dataType={'date'}
                              hidingPriority={7}
                              minWidth={100}
                              width={110}
                              alignment="center"
                                   >
                         </Column>
                         <Column dataField={''}
                              caption={'Residencia'}
                              cellRender={ cellHomeRenderHandler }
                              hidingPriority={6}  />
                         <Column dataField={''}
                              caption={'Información'}
                              cellRender={ cellInformationRenderHandler }
                              hidingPriority={6}  />

                         <MasterDetail
                              enabled = {true}
                              render = { e => <VisitDetail visit = { e.data } /> }
                         />
                    </DataGrid>   
                    </div>
               </div>
          </React.Fragment>  
     )
}
export default Visits;
import React, { useState, useRef, useCallback } from 'react';
import { Link, useNavigate } from "react-router-dom";
import Form, {
    Item,
    Label,
    ButtonItem,
    ButtonOptions,
    RequiredRule,
} from 'devextreme-react/form';
import LoadIndicator from 'devextreme-react/load-indicator';
import { myNotify} from '../../ui/myNotify';
import { resetPassword } from '../../api/auth';
import './ResetPasswordForm.scss';

export default function ResetPasswordForm() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const formData = useRef({ username: '' });
    
    const onSubmit = (e) => {
        e.preventDefault();
        const { username } = formData.current;
        setLoading(true);
     
        resetPassword(username).then( res => {
            if (res.isOk) {
                myNotify('Hemos enviado un correo para configurar tu contraseña.', 'success');
                navigate('/login');
            } else
            {
                myNotify(res.message, 'error');
                setLoading(false);
            }
        }).catch( err => console.log(err));
    };
    
    return (
        <form className={'reset-password-form'} onSubmit={onSubmit}>
            <Form formData={formData.current} disabled={loading}>
                <Item
                    dataField={'username'}
                    editorType={'dxTextBox'}
                    editorOptions={emailEditorOptions}
                    >
                    <RequiredRule message="Requerido" />
                    <Label visible={false} />
                </Item>
                <ButtonItem>
                    <ButtonOptions
                        elementAttr={submitButtonAttributes}
                        width={'100%'}
                        type={'default'}
                        useSubmitBehavior={true}
                        >
                        <span className="dx-button-text">
                        {
                            loading
                            ? <LoadIndicator width={'24px'} height={'24px'} visible={true} />
                            : 'Enviar Correo'
                        }
                        </span>
                    </ButtonOptions>
                </ButtonItem>
                <Item>
                    <div className={'login-link'}>
                    Regresar a <Link to={'/login'}>Acceso a Portal</Link>
                    </div>
                </Item>
            </Form>
        </form>
        );
    }
    const emailEditorOptions = { stylingMode: 'filled', placeholder: 'Usuario / Email', mode: 'text' };
    const submitButtonAttributes = { class: 'submit-button' };
    
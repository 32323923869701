import { Button, Popup } from 'devextreme-react';
import React from 'react'

const MyPopup = ({ caption, visible, width, height, children, onHidden, showCloseButton = true  }) => {

    const TitleComponent = () => {
        return (
            <div style = {{ display: 'flex' }}>
                <h4 style = { { flex:1, padding: 5, margin: 0, textAlign: "center", color: '#10273b', backgroundColor: '#94b1c9', borderRadius: 4 } }>
                    { caption ?? "" }  
                </h4>
                { showCloseButton === true && <Button type='outline' style = {{ marginLeft: 10 }} icon='close' onClick={ onHidden ?? null } />}
            </div>
        )
    }

    return (
        <Popup
            titleComponent={ TitleComponent }
            visible = { visible }
            dragEnabled = { true } 
            hideOnOutsideClick = { true }           
            onHidden={ onHidden ?? null }
            
            width = { width ?? 800 }       
            height={ height ?? "auto" }
            container=".dx-viewport" 
            >

            {
                children ?? null
            }

        </Popup>
   )
}
export default MyPopup 
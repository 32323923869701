import React, { useEffect, useState } from 'react'
import './suppliers.scss';
import { ToolbarItem } from 'devextreme-react/popup';
import Form, { SimpleItem, GroupItem, ButtonItem } from 'devextreme-react/form';
import { httpDelete, httpGet, httpPost, httpPut } from '../../api/methods';
import MyPopup from '../../components/popup/Popup';

const SupplierForm = ({ id, onHidden }) => {
    const [formData, setFormData] = useState({});
    const [validationError, setValidationError] = useState(null);
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    useEffect( () => {
        if (id !== 'new') {
            httpGet('/suppliers/' + id).then( res => {
                let supplier = res.data;
                setFormData({
                    name: supplier.name,
                    phone: supplier.phone,
                    email: supplier.email,
                });                       
            }).catch( e => console.log(e) );
        } else {
            setFormData({
                name: '',
                phone: '',
                email: '',
            });
        }
    }, [ ] );
    const submitHandler = (e) => {
        e.preventDefault();
        //  Validate
        saveSupplier().then( res => {
            if (res.isOk)   closeForm();
            else            setValidationError(res.message);            
        }).catch( e => console.log(e) );
    }
    const saveSupplier = () => {
        return new Promise((resolve, reject) => {
            if (id === 'new') {
                //  posting new
                httpPost('/suppliers/', formData).then( res => {
                    resolve(res);
                }).catch( e => console.log(e) );
            } else {
                //  updating
                httpPut('/suppliers/' + id, formData).then( res => {
                    resolve(res);
                }).catch( e => console.log(e) );
            }    
        })
    }
    const onHidingHandler = () => { closeForm(); }
    const closeForm = () => { if (onHidden) onHidden(); }
    const deleteHandler = ({ confirmed }) => {
        if (!confirmed) {
            setShowDeleteConfirmation(true);
            return;
        }
        //  Delete
        httpDelete('/suppliers/' + id).then( res => {
            if (res.isOk)   onHidingHandler();
            else            console.log(res);
        })
    }
    const acceptButtonOptions = {
        text: id === 'new'  
              ? 'Agregar'
              : 'Actualizar',
        type: 'default',
        useSubmitBehavior: true,  
    }
    const deleteButtonOptions = {
        text: 'Eliminar',
        type: 'danger-outline',
        useSubmitBehavior: false,   
        onClick: () => deleteHandler({ confirmed: false })
    }
    let caption = id === 'new' 
                    ? 'Agregar Proveedor' 
                    : 'Editar Proveedor: ' + formData.name;

    return (
        <>
            <MyPopup caption = { caption } visible={ true } onHidden={ onHidden } width={ 600 }  >  
                <form onSubmit={ submitHandler }>
                    <Form 
                        formData = { formData }
                        labelLocation='top'
                        labelMode='floating'
                        >
                        <GroupItem colCount={2}>
                            <SimpleItem dataField='name'            label={ { text: 'Nombre de Proveedor' } }   colSpan={ 2 } isRequired={ true } />
                            <SimpleItem dataField='contactNames'    label={ { text: 'Nombre de Contacto(s)' } } colSpan={ 2 } />
                            <SimpleItem dataField='phone'           label={ { text: 'Teléfono' } }              colSpan={ 1 } />
                            <SimpleItem dataField='email'           label={ { text: 'Correo' } }                colSpan={ 1 } />
                        </GroupItem>
                        <GroupItem colCount={2}>
                            <SimpleItem colSpan={2}> <hr style = {{ margin: '0px', height: '1px', backgroundColor: '#ccc', border: 'none' }} /> </SimpleItem>
                            {   id === 'new'
                                ? <SimpleItem colSpan={1} ></SimpleItem>
                                : <ButtonItem horizontalAlignment='left' buttonOptions={ deleteButtonOptions } colSpan={1}/>
                            }
                            <ButtonItem horizontalAlignment='right' buttonOptions={ acceptButtonOptions }   colSpan={1} />
                        </GroupItem>
                    </Form>
                    {   validationError && <p className = 'apiError'> { validationError  } </p>  }
                </form>
            </MyPopup>
            <MyPopup   
                caption='Confirmar'
                visible = { showDeleteConfirmation }
                onHidden={ () => setShowDeleteConfirmation(false) }
                width = { 'auto' }       
                height={ 'auto' }
                >
                    <p>Desea eliminar al Proveedor ?</p> 
                <ToolbarItem 
                    widget = 'dxButton'
                    toolbar = 'bottom'
                    location = 'center'
                    options = { 
                        {
                            text: 'Aceptar',
                            onClick: () => deleteHandler({ confirmed: true })
                        }
                    }
                />
            </MyPopup>
        </>    
    )
}
export default SupplierForm;
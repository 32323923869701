import React, { useEffect, useRef, useState } 
                            from 'react'
import DataGrid, { Column, Pager, Paging } 
                            from 'devextreme-react/data-grid';
import { useNavigate }      from 'react-router-dom'
import { myNotify}          from '../../ui/myNotify';
import AccountForm          from './AccountForm';
import DatagridHeader       from '../../components/datagrid-header/DatagridHeader';
import { httpGet } from '../../api/methods';

const Accounts = () => {
    const navigate = useNavigate();
    const [editId, setEditId] = useState(null);
    const selectedId = useRef(null);
    const [accounts, setAccounts] = useState(null);

    useEffect( () => { 
        //  load accounts
        loadAccounts();
    }, [ editId ])
    const loadAccounts = () => [
        httpGet('accounts').then( res => {
            if (res.isOk) {
                setAccounts(res.data);
            }
        })
    ]

    const addHandler = () => { setEditId("new"); }
    const editHandler = (e) => {
        //  Validate
        if (e.event?.type === 'keydown' && e.event.key !== 'F2') return;
        if (selectedId.current) {
            setEditId(selectedId.current);
            return;
        }
        myNotify('Seleccionar Cuenta para Editar', 'error');
    }
    const reportHandler = () => {
        if (selectedId.current)        {
            navigate('/accounts/' + selectedId.current)
            return;
        }
        myNotify('Seleccionar Cuenta para ver Estado', 'error');       
    }
    const refreshHandler = () => {
        loadAccounts();
    }  

    const calculateBalanceCellValue = (e) => {
        let balance = e.initialBalance;
        if (e.items?.length > 0)
            balance += e.items[0].sum;
        return balance ;
    }

    return (
        <React.Fragment>
            <div className={'content-block'} >
                <div className={'dx-card responsive-paddings'} style={{ position: 'relative', boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)' }}>
                    <DatagridHeader title = { { banner: 'Cuentas' } }  
                                    navs = { [  { icon: 'refresh',  hint: 'Actualizar',     onClick: refreshHandler },
                                                { icon: 'doc',      hint: 'Mostrar Estado', onClick: reportHandler },
                                                { icon: 'edit',     hint: 'Editar Cuenta',  onClick: editHandler },
                                                { icon: 'add',      hint: 'Agregar Cuenta', onClick: addHandler }
                                            ] }
                    />
                    <DataGrid 
                        keyExpr={ 'id' }
                        className={'dx-card wide-card'}
                        dataSource={ accounts }
                        columnMinWidth={100}
                        columnAutoWidth={true}                       
                        onFocusedRowChanged={ (e) => { selectedId.current = e.row.data.id; } }
                        onKeyDown={ editHandler }
                        onRowDblClick={ reportHandler } >

                            <Paging defaultPageSize = { 10 } />
                            <Pager showPageSizeSelector={true} showInfo={true} />
                            <Column dataField={'id'}            visible={false} />
                            <Column dataField={'type'}          caption={'Tipo'}            width={ 100 }/>
                            <Column dataField={'beneficiary'}   caption={'Beneficiario'}    width={"50%"}/>
                            <Column dataField={'bank'}          caption={'Banco'} />
                            <Column dataField={'number'}        caption={'Numero de Cuenta'} alignment='right'  />
                            <Column dataField={'balance'}       caption={'Balance'}         calculateCellValue={ calculateBalanceCellValue } width={ 150 }   format={{ type: 'currency', precision: 2}}/>  
                    </DataGrid>
                    { editId && <AccountForm id = { editId } onHidden={ () => setEditId(null) } />}
                </div>
            </div>
        </React.Fragment>
    )
}
export default Accounts;
import React, { useState, useEffect, createContext, useContext } from 'react';
import { httpGet } from '../api/methods';

const AppInfoContext = createContext({ });
const useAppInfo = () => useContext(AppInfoContext);

function AppInfoProvider(props) {
    const [info, setInfo] = useState();
    
    useEffect(() => {
        httpGet('client/info').then( res => {           
            if (res.isOk) {
                setInfo({
                    client: {
                        name: res.data.name,
                        city: res.data.city,
                    },
                    subDomain: res.data.subDomain,
                    appName: 'Residencial Pro',
                    version: '1.00'
                });
            }
        }).catch()
    }, []);

    return ( <AppInfoContext.Provider value={{ info }} {...props} /> );
}

export { AppInfoProvider, useAppInfo }   
import React, { useState, useEffect, createContext, useContext } from 'react';
import { getUser, signIn as sendSignInRequest, signOut as sendSignOutRequest } from '../api/auth';

function AuthProvider(props) {
    const [user, setUser] = useState();
    const [loading, setLoading] = useState(true);
    
    useEffect(() => {
        getUser().then(res => {
            if (res.isOk) setUser(res.data);
            setLoading(false);
        }).catch ( () => setLoading(false))
    }, []);
    
    const signIn = (username, password) => {
        return new Promise((resolve, reject) => {
            sendSignInRequest(username, password).then(res => {
                if (res.isOk) {
                    setUser(res.data);
                    resolve(res);
                } else {
                    resolve(res);
                }
            }).catch(err => console.log(err))
        });
    };
    const signOut = () => {
        sendSignOutRequest();
        setUser(undefined);
    };
    return ( <AuthContext.Provider value={{ user, signIn, signOut, loading }} {...props} /> );
    }

    const AuthContext = createContext({ loading: false });
    const useAuth = () => useContext(AuthContext);
   
    export { AuthProvider, useAuth }   
import React, { useEffect, useRef, useState } from 'react'
import { ToolbarItem } from 'devextreme-react/popup';
import Form, { SimpleItem, GroupItem, ButtonItem } from 'devextreme-react/form';
import { httpDelete, httpGet, httpPost, httpPut } from '../../api/methods';
import MyPopup from '../../components/popup/Popup';
import './users.scss';
import { CheckBox, TagBox } from 'devextreme-react';

const UserForm = ({ id, onHidden }) => {
    const [formData, setFormData] = useState({});
    const [validationError, setValidationError] = useState(null);
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

    useEffect( () => {
        if (id === 'new') {
            setFormData({
                access: false,
            });
        } else {
            httpGet('/users/' + id).then( res => {
                let user = res.data;
                setFormData({
                    access: user.access,
                    username: user.username,
                    fName: user.fName,
                    lName: user.lName,
                    phone: user.phone,
                    email: user.email,
                    isContractor: user.isContractor,
                    isSystem: user.isSystem,
                    isAdmin: user.isAdmin,
                    isGuard: user.isGuard,
                });       
            }).catch( e => console.log(e) );
        }
    }, [ ] );

    const submitHandler = (e) => {
        e.preventDefault();
        //  Validate
        saveUser().then( res => {
            if (res.isOk){ 
                closeForm();
            }
            else            
                setValidationError(res.message);
        }).catch( e => console.log(e) );
    }

    const saveUser = () => {
        return new Promise((resolve, reject) => {
            let body = { ...formData }
            delete body.isContractor;
            if (id === 'new') {
                //  posting new
                httpPost('/users/', body).then( res => {
                    resolve(res);
                }).catch( e => console.log(e) );
            } else {
                //  updating
                httpPut('/users/' + id, body).then( res => {
                    resolve(res);
                }).catch( e => console.log(e) );
            }    
        })
    }
    const onHidingHandler = () => { closeForm(); }
    const closeForm = () => { if (onHidden) onHidden(); }
    const deleteHandler = ({ confirmed }) => {
        if (!confirmed) {
            setShowDeleteConfirmation(true);
            return;
        }
        //  Delete
        httpDelete('/users/' + id).then( res => {
            if (res.isOk)   onHidingHandler();
            else            console.log(res);
        })
    }
    const acceptButtonOptions = {
        text: id === 'new'  
              ? 'Agregar'
              : 'Actualizar',
        type: 'default',
        useSubmitBehavior: true,  
    }
    const deleteButtonOptions = {
        text: 'Eliminar',
        type: 'danger-outline',
        useSubmitBehavior: false,   
        onClick: () => deleteHandler({ confirmed: false })
    }
    let caption = id === 'new' 
                    ? 'Agregar Usuario' 
                    : 'Editar Usuario: ' + formData.fName + ' ' + formData.lName;

                    return (
        <>
            <MyPopup caption = { caption } visible={ true } onHidden={ onHidden } width={ 800 }  >  
                <form onSubmit={ submitHandler }>
                    <Form 
                        formData = { formData }
                        labelLocation='top'
                        labelMode='floating'
                        >

                        <GroupItem colCount={3} >
                            <GroupItem colSpan={2} colCount={12} caption='Información'>
                                <SimpleItem dataField='username'        label={ { text: 'Usuario' } }               colSpan={ 3 } isRequired={ true } />
                                <SimpleItem dataField='fName'           label={ { text: 'Nombre' } }                colSpan={ 4 } isRequired={ true } />
                                <SimpleItem dataField='lName'           label={ { text: 'Apellidos' } }             colSpan={ 5 } isRequired={ true } />

                                <SimpleItem dataField='phone'           label={ { text: 'Teléfono' } }              colSpan={ 6 } />
                                <SimpleItem dataField='email'           label={ { text: 'Correo' } }                colSpan={ 6 } />
                            </GroupItem>

                            <GroupItem colSpan={1} caption='Credenciales' >
                                <SimpleItem cssClass={'accessBox'}  dataField='access'      label={ { text: 'Acceso',           location: "right", showColon: false } }   editorType={'dxCheckBox'} />
                                <SimpleItem cssClass={'accessBox'}  dataField='isSystem'    label={ { text: 'Sistema',          location: "right", showColon: false } }   editorType={'dxCheckBox'} />
                                <SimpleItem cssClass={'accessBox'}  dataField='isAdmin'     label={ { text: 'Administrador',    location: "right", showColon: false } }   editorType={'dxCheckBox'} />
                                <SimpleItem cssClass={'accessBox'}  dataField='isGuard'     label={ { text: 'Guardia',          location: "right", showColon: false } }   editorType={'dxCheckBox'} />
                            </GroupItem>
                        </GroupItem>

                        <GroupItem colCount={2}>
                            <SimpleItem colSpan={2}> <hr style = {{ margin: '0px', height: '1px', backgroundColor: '#ccc', border: 'none' }} /> </SimpleItem>
                            {   id === 'new'
                                ?   <SimpleItem colSpan={1} />
                                :   <ButtonItem horizontalAlignment='left' buttonOptions={ deleteButtonOptions } colSpan={1}/>
                            }
                            <ButtonItem horizontalAlignment='right' buttonOptions={ acceptButtonOptions }   colSpan={1} />
                        </GroupItem>
                    </Form>
                    {   validationError && <p className = 'apiError'> { validationError  } </p>  }
                </form>
            </MyPopup>
            <MyPopup   
                caption='Confirmar'
                visible = { showDeleteConfirmation }
                onHidden={ () => setShowDeleteConfirmation(false) }
                width = { 'auto' }       
                height={ 'auto' }
                >
                    <p>Desea eliminar usuario ?</p> 
                <ToolbarItem 
                    widget = 'dxButton'
                    toolbar = 'bottom'
                    location = 'center'
                    options = { 
                        {
                            text: 'Aceptar',
                            onClick: () => deleteHandler({ confirmed: true })
                        }
                    }
                />
            </MyPopup>
        </>    
    )
}
export default UserForm;
import { DateBox, Button }           from 'devextreme-react';
import './datagridHeader.scss'
const DatagridHeader = ({ title, navs, imageName }) => {
    let linesSpacing = 0;
    if (title?.line1) linesSpacing += 20;
    if (title?.line2) linesSpacing += 20;
    if (title?.line3) linesSpacing += 20;

    let bannerImage = require('./images/banner-money.jpg');
    if (imageName) bannerImage = require('./images/banner-' + imageName + '.jpg');

    return (
        <>
            <img src={ bannerImage } alt='banner' />

            {/* Title - Banner */}
            { title.banner && 
                <div className="titleBanner">
                    { title.banner }
                </div> 
            }

            <div style = { { position: "relative" } }>
                { title?.line1  && <h4 style={{ position: 'absolute', top: -20, left: 50, zIndex: 1 } } >{ title.line1 }</h4> }
                { title?.line2  && <h4 style={{ position: 'absolute', top: -0,  left: 50, zIndex: 1 } } >{ title.line2 }</h4> }
                { title?.line3  && <h4 style={{ position: 'absolute', top: 20,  left: 50, zIndex: 1 } } >{ title.line3 }</h4> }
            </div>
            <div>
                <div className='navBox' style = {{ top: 43 + (linesSpacing > 20 ? (linesSpacing + 12) : 0) }}  >
                    {
                        navs?.map( (item, index) => {
                            return(
                                item.visible !== false &&
                                <div key={ 'nav' + index }>
                                    { item.dateBox &&
                                        <DateBox className={'animateButtons navDateBox' }
                                            key={ 'navDateBox' + index }
                                            label={ item?.label }
                                            width={ 130 }                                        
                                            defaultValue={ item?.defaultValue } 
                                            max={ item?.max }   
                                            min={ item?.min }   
                                            onValueChanged={ ({ value }) => item?.onValueChanged(value) }   
                                            /> 
                                    }
                                    {   !item.dateBox &&
                                        <Button className={'animateButtons navButton'}
                                            key={ 'navButton' + index }
                                            onClick={ item.onClick }	                                        
                                            text={ item.icon ? null : item.caption  }
                                            icon={ item.icon }
                                            disabled={ item.disabled ?? false }
                                            hint={ item.hint }
                                            height = { item.height ?? 30 } width = { item.icon ? 50 : (item.width ?? 100) }
                                            type='default' />
                                    }
                                </div>
                        )})
                    }
                </div>
            </div>
            <div style = {{ height: linesSpacing + 5 }}>  

            </div>
        
	    </>
    )
}
export default DatagridHeader
import axios from 'axios';
import CustomStore from 'devextreme/data/custom_store';

export const getStore = (models, urlParams) => {
    let loadURL = models;
    loadURL += urlParams ? urlParams : "";          // it must start with "?"
    return new CustomStore({
        key: 'id',
        load: (loadOptions) => {
            return axios.get(loadURL);
        },
        insert: (values) => {
            return axios.post(models, values);
        },
        update: (key, values) => {
            return axios.put(models + '/' + key, values);
        },
        remove: (key) => {
            return axios.delete(models + '/' + key);
        }
    });
}
export const getResidentHomesStore = (residentId) => {
    return new CustomStore({
        key: 'id',
        load: (loadOptions) => {
            return axios.get('residents/'+ residentId + '/homes');
        },
        insert: (values) => {
            return axios.post('homes/residents', values);
        },
        update: (key, values) => {
            return axios.put('homes/residents' + key, values);
        },
        remove: (key) => {
            return axios.delete('homes/residents' + key);
        }
    });
}
export const getHomeResidentsStore = (homeId) => {
    return new CustomStore({
        key: 'id',
        load: (loadOptions) => {
            return axios.get('homes/'+ homeId + '/residents');
        },
        insert: (values) => {
            return axios.post('homes/residents', values);
        },
        update: (key, values) => {
            return axios.put('homes/residents' + key, values);
        },
        remove: (key) => {
            return axios.delete('homes/residents' + key);
        }
    });
} 
import React, { useEffect, useRef, useState } from 'react'
import { ToolbarItem } from 'devextreme-react/popup';
import Form, { SimpleItem, GroupItem, ButtonItem, Label } from 'devextreme-react/form';
import { httpDelete, httpGet, httpPost, httpPut } from '../../api/methods';
import MyPopup from '../../components/popup/Popup';
import './homes.scss';
import { TagBox, List, CheckBox } from 'devextreme-react';
import { TextBox, Button as TextBoxButton } from 'devextreme-react/text-box';


const HomeForm = ({ id, onHidden }) => {
    const [formData, setFormData] = useState({});
    const [sections, setSections] = useState([]);
    const [residents, setResidents] = useState([]);
    const [homeTags, setHomeTags] = useState([]);
    const [validationError, setValidationError] = useState(null);
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const refKeepAdding = useRef(false);
    const selectedTagIds = useRef([]);
    const selectedResidentIds = useRef([]);

    useEffect( () => {
        httpGet('residents').then( loadedResidents => {
            if (loadedResidents.isOk) {
                setResidents(loadedResidents.data);
            }

            if (id === 'new') {
                //  Adding new
                httpGet('sections/').then( res => {
                    if (res.isOk) setSections(res.data)
                    setFormData({ 
                        sectionId: null, 
                        showFamily: true,
                        accessTags: true,
                        accessTagsExpireDate: Date.now() + 14 * 24 * 60 * 60 * 1000, 
                        applyWireProofIntent: true,
                        applyWireProofIntentDate: Date.now(),
                    });
                }).catch( e => console.log(e) );
            } else {
                //  Getting 
                httpGet('/homes/' + id).then( res => {
                    let home = res.data;
                    console.log(home);
                    setFormData({
                        sectionId: home.sectionId,
                        section: home.section,
                        street: home.street,
                        number: home.number,
                        showFamily: home.showFamily,
                        familyName: home.familyName,
                        accessTags: home.accessTags,
                        accessTagsExpireDate: home.accessTagsExpireDate, 
                        applyWireProofIntent: home.applyWireProofIntent,
                        applyWireProofIntentDate: home.applyWireProofIntentDate,
                    });                       
                    res.data.residents.forEach(r => {
                        selectedResidentIds.current.push(r.id);
                    });
                    res.data.tags.forEach(t => {
                        selectedTagIds.current.push(t.id);
                    });

                    setSections([ res.data.section ]);
                    setHomeTags(res.data.tags);
                }).catch( e => console.log(e) );
            }

        }).catch( e => console.log(e) );
    }, [ ] );

    const submitHandler = (e) => {
        e.preventDefault();

        //  Validate
        saveHome().then( res => {
            if (res.isOk)   
            {
                if (id !== 'new' || (id === 'new' && refKeepAdding.current === false)) 
                    closeForm();

                //  Clear Form and keep adding
                selectedResidentIds.current = [];
                selectedTagIds.current =[];
                setFormData(prev => ({ ...prev,
                    number: '',
                    familyName: '',

                }))
            }
            else            
                setValidationError(res.message);            
        }).catch( e => console.log(e) );
    }
    const saveHome = () => {
        return new Promise((resolve, reject) => {
            let body = { ...formData, residentIds: selectedResidentIds.current, tagIds: selectedTagIds.current };
            delete body.section;
            if (id === 'new') {
                //  posting new
                httpPost('/homes/', body ).then( res => {
                    resolve(res);
                }).catch( e => console.log(e) );
            } else {
                //  updating
                httpPut('/homes/' + id, body).then( res => {
                    resolve(res);
                }).catch( e => console.log(e) );
            }    
        })
    }
    const onHidingHandler = () => { closeForm(); }
    const closeForm = () => { if (onHidden) onHidden(); }
    const deleteHandler = ({ confirmed }) => {
        if (!confirmed) {
            setShowDeleteConfirmation(true);
            return;
        }
        //  Delete
        httpDelete('/homes/' + id).then( res => {
            if (res.isOk)   onHidingHandler();
            else            console.log(res);
        })
    }
    const acceptButtonOptions = {
        text: id === 'new'  
              ? 'Agregar'
              : 'Actualizar',
        type: 'default',
        useSubmitBehavior: true,  
    }
    const deleteButtonOptions = {
        text: 'Eliminar',
        type: 'danger-outline',
        useSubmitBehavior: false,   
        onClick: () => deleteHandler({ confirmed: false })
    }
    let caption = id === 'new' 
                    ? 'Agregar Residencia' 
                    : 'Editar Residencia en ' + formData.section?.name;

    const sectionOptions = {
        visible: id === 'new',
        items: sections,
        valueExpr: "id",
        displayExpr: "name",
        // onValueChanged: ({value}) => sectionSelectionHandler(value)
    }

    const applyWireProofOptions = {
        hint:   'Al hacer pago de cuota o deposito,\n' +
                'permite pre-aprobar la cantidad indicada por colono.\n\n' + 
                'Este beneficia al colono porque se pueden liberar servicios,\nen caso que hayan estado suspendidos,\n' +
                'sin tener que esperar aprobación de administradores.\n  ',
        onValueChanged: ({value}) => setFormData(prev => ({ ...prev, applyWireProofIntent: value }))
    }
    const applyWireProofOptionsDate = {
        visible: formData?.applyWireProofIntent,
        hint:   'Si el colono no ha sido honesto con las cantidades depositadas,\n' +
                'se puede bloquear el beneficio temporalmente.\n\n' + 
                'Esta fecha indica cuando se le permitiría al colono\nla pre-aprobación de comprobantes de pago.\n  '
    }
    const onCustomItemCreatingHandler = args => {
        //  validate
        if (!args.text) {
            args.customItem = null;
            return;
        }
        const { component, text } = args;
        const currentItems = component.option('items');   
        const newItem = {
            id: text.trim(),            //  set id as RFID, this will make the web API create/add the tag since it will be seen as new
            RFID: text.trim(),
        };
     
        const itemInDataSource = currentItems.find((item) => item.text === newItem.RFID)
        if (itemInDataSource) {
            args.customItem = itemInDataSource;
        } else {    
            currentItems.push(newItem);
            component.option('items', currentItems);
            args.customItem = newItem;
        }
    }

    const Tags = () => {
        return (
            <div className="tagBox">
                <TagBox 
                    items = { homeTags }
                    label='ID Tags'
                    placeholder='Seleccionar'
                    noDataText='...'
                    valueExpr="id"
                    displayExpr={ 'RFID' }     
                    searchEnabled={false}
                    defaultValue={ selectedTagIds.current }
                    onValueChanged={ e => selectedTagIds.current = e.value }
                    acceptCustomValue={true}
                    onCustomItemCreating={ onCustomItemCreatingHandler }
                    hint='Para agregar tag nuevo, teclear numero de TagID y [ ENTER ]'
                    dropDownOptions={ {
                        disabled: false
                    }}
                />
            </div>
        )
    }
    const Residents = () => {
        return (
            <div style = {{ width: "auto" }}> 
                <TagBox
                    items={ residents }
                    // label='Colonos'
                    placeholder='Seleccionar'
                    noDataText='...'
                    valueExpr="id"
                    displayExpr="info"
                    searchEnabled={true}                   
                    defaultValue={ selectedResidentIds.current }
                    onValueChanged={ e => selectedResidentIds.current = e.value }
                    hideSelectedItems={ true }
                />
            </div>
        )
    }

    return (
        <>
            <MyPopup caption = { caption } visible={ true } onHidden={ onHidden } width={ 800 }  >  
                <form onSubmit={ submitHandler }>
                    <Form 
                        formData = { formData }
                        labelLocation = 'top'
                        labelMode = 'floating'
                        >
                        <GroupItem colCount={6}>
                            <GroupItem caption={'Información'}  colSpan={6} colCount={6}>
                                <SimpleItem dataField="sectionId"     label={ { text: "Fraccionamiento" } } colSpan={ 6 } isRequired={ id === 'new' }  
                                            editorType={ "dxSelectBox" } 
                                            editorOptions={ sectionOptions } /> 
                                <SimpleItem dataField='street'          label={ { text: 'Calle' } }         colSpan={ 4 } isRequired={ true } />
                                <SimpleItem dataField='number'          label={ { text: 'Número' } }        colSpan={ 2 } isRequired={ true } />

                                <SimpleItem dataField='familyName'      label={ { text: 'Familia' } }       colSpan={ 4 }   />
                                <SimpleItem dataField="showFamily"      label={ { text: "Mostrar Nombre de Familia" } }      colSpan={ 2 }   editorType={ "dxCheckBox"} />       

                                <SimpleItem dataField="applyWireProofIntent"        label={ { text: "Pre-aprobación Comprobantes" } }  colSpan={ 2 }   
                                            editorType={ "dxCheckBox"}
                                            editorOptions={ applyWireProofOptions } /> 
                                <SimpleItem dataField='applyWireProofIntentDate'    label={ { text: 'Aceptar desde' } }      colSpan={ 2 }   
                                            editorType={'dxDateBox'}
                                            editorOptions={ applyWireProofOptionsDate } /> 

                            </GroupItem>

                            <GroupItem caption={'Acceso Vehicular'} colSpan={3} colCount={4}>
                                <SimpleItem dataField='accessTagsExpireDate'    label={ { text: 'Fecha Expiración' } }  colSpan={ 2 }   
                                            editorType={'dxDateBox'}
                                />
                                <SimpleItem dataField="accessTags"              label={ { text: "Permitir Acceso" } }           colSpan={ 2 }   editorType={ "dxCheckBox"} />       
                                <SimpleItem colSpan={4}>
                                    <Tags />
                                </SimpleItem>
                            </GroupItem>                           

                            <GroupItem caption={'Colonos'} colSpan={3} >
                                <Residents />
                            </GroupItem>

                        </GroupItem>
                        
                        <GroupItem colCount={2}>
                            <SimpleItem colSpan={2}> <hr style = {{ margin: '0px', height: '1px', backgroundColor: '#ccc', border: 'none' }} /> </SimpleItem>
                            {   id === 'new'
                                ?   <SimpleItem colSpan={1} >
                                        <CheckBox 
                                            text='Seguir agregando al terminar cada registro?' 
                                            hint='Ventana sigue abierta después de cada registro' 
                                            onValueChanged={ ({value}) => refKeepAdding.current = value }
                                        />
                                    </SimpleItem>
                                :   <ButtonItem horizontalAlignment='left' buttonOptions={ deleteButtonOptions } colSpan={1}/>
                            }
                            <ButtonItem horizontalAlignment='right' buttonOptions={ acceptButtonOptions }   colSpan={1} />
                        </GroupItem>

                    </Form>
                    {   validationError && <p className = 'apiError'> { validationError  } </p>  }
                </form>
            </MyPopup>
            <MyPopup   
                caption='Confirmar'
                visible = { showDeleteConfirmation }
                onHidden={ () => setShowDeleteConfirmation(false) }
                width = { 'auto' }       
                height={ 'auto' }
                >
                    <p>Desea eliminar residencia ?</p> 
                <ToolbarItem 
                    widget = 'dxButton'
                    toolbar = 'bottom'
                    location = 'center'
                    options = { 
                        {
                            text: 'Aceptar',
                            onClick: () => deleteHandler({ confirmed: true })
                        }
                    }
                />
            </MyPopup>
        </>    
    )
}
export default HomeForm;
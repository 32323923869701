import axios from "axios";

export const httpPost = (url, body) => {
     return new Promise( (resolve, reject) => {
          axios.post(url, body).then( res => {
               return resolve({
                    isOk: true,
                    data: res.data,
                    message: 'Ok'
               })
          }).catch(err => {
               return resolve({
                    isOk: false,
                    data: null,
                    message: err.response?.data?.message
               })
          });
     });
}
export const httpGet = (url, config = null) => {
     return new Promise( (resolve, reject) => {
          axios.get(url, config).then( res => {
               return resolve({
                    isOk: true,
                    data: res.data,
                    message: 'Ok'
               })
          }).catch(err => {
               return resolve({
                    isOk: false,
                    data: null,
                    message: err.response?.data?.message
               })
          });
     });
}
export const httpPut = (url, body) => {
     return new Promise( (resolve, reject) => {
          axios.put(url, body).then( res => {
               return resolve({
                    isOk: true,
                    data: res.data,
                    message: 'Ok'
               })
          }).catch(err => {
               return resolve({
                    isOk: false,
                    data: null,
                    message: err.response?.data?.message
               })
          });
     });
}
export const httpDelete = (url) => {
     return new Promise( (resolve, reject) => {
          axios.delete(url).then( res => {
               return resolve({
                    isOk: true,
                    data: res.data,
                    message: 'Ok'
               })
          }).catch(err => {
               return resolve({
                    isOk: false,
                    data: null,
                    message: err.response?.data?.message
               })
          });
     });
}
import React, { useEffect, useState } from 'react'
import { ToolbarItem } from 'devextreme-react/popup';
import Form, { SimpleItem, GroupItem, ButtonItem } from 'devextreme-react/form';
import { httpDelete, httpGet, httpPost, httpPut } from '../../api/methods';
import MyPopup from '../../components/popup/Popup';
import './sections.scss';

const SectionForm = ({ id, onHidden }) => {
    const [formData, setFormData] = useState({});
    const [validationError, setValidationError] = useState(null);
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    useEffect( () => {
        if (id !== 'new') {
            httpGet('/sections/' + id).then( res => {
                let section = res.data;
                setFormData({
                    name: section.name,
                    emailMonthlyReport: section.emailMonthlyReport,
                });                       
            }).catch( e => console.log(e) );
        } else {
            setFormData({
                name: '',
            });
        }
    }, [ ] );
    const submitHandler = (e) => {
        e.preventDefault();
        //  Validate
        saveSection().then( res => {
            if (res.isOk)   closeForm();
            else            setValidationError(res.message);            
        }).catch( e => console.log(e) );
    }
    const saveSection = () => {
        return new Promise((resolve, reject) => {
            if (id === 'new') {
                //  posting new
                httpPost('/sections/', formData).then( res => {
                    resolve(res);
                }).catch( e => console.log(e) );
            } else {
                //  updating
                httpPut('/sections/' + id, formData).then( res => {
                    resolve(res);
                }).catch( e => console.log(e) );
            }    
        })
    }
    const onHidingHandler = () => { closeForm(); }
    const closeForm = () => { if (onHidden) onHidden(); }
    const deleteHandler = ({ confirmed }) => {
        if (!confirmed) {
            setShowDeleteConfirmation(true);
            return;
        }
        //  Delete
        httpDelete('/sections/' + id).then( res => {
            if (res.isOk)   onHidingHandler();
            else            console.log(res);
        })
    }
    const acceptButtonOptions = {
        text: id === 'new'  
              ? 'Agregar'
              : 'Actualizar',
        type: 'default',
        useSubmitBehavior: true,  
    }
    const deleteButtonOptions = {
        text: 'Eliminar',
        type: 'danger-outline',
        useSubmitBehavior: false,   
        onClick: () => deleteHandler({ confirmed: false })
    }
    let caption = id === 'new' 
                    ? 'Agregar Fraccionamiento' 
                    : 'Editar Fraccionamiento: ' + formData.name;
    return (
        <>
            <MyPopup caption = { caption } visible={ true } onHidden={ onHidden } width={ 600 } >  
                <form onSubmit={ submitHandler }>
                    <Form 
                        formData = { formData }
                        labelLocation='top'
                        labelMode='floating'
                        >
                        <GroupItem colCount={2}>
                            <SimpleItem dataField='name'                label={ { text: 'Nombre del Fraccionamiento' } }        
                                        colSpan={ 2 } 
                                        isRequired={ true }
                            />
                            <SimpleItem dataField='emailMonthlyReport'  
                                        label={ { text: 'Reporte Mensual', showColon: false } }  
                                        colSpan={ 1 } 
                                        editorType={ 'dxCheckBox' }
                                        editorOptions={ {
                                            hint: 'Habilita el envío de reporte mensual\na todos los colonos del fraccionamiento.'
                                        }}
                            />
                        </GroupItem>

                        <GroupItem colCount={2}>
                            <SimpleItem colSpan={ 2 }>
                                <hr style = {{ margin: '0px' }} />
                            </SimpleItem>
                            {   id === 'new'
                                ? <SimpleItem colSpan={1} ></SimpleItem>
                                : <ButtonItem horizontalAlignment='left' buttonOptions={ deleteButtonOptions } colSpan={1}/>
                            }
                            <ButtonItem horizontalAlignment='right' buttonOptions={ acceptButtonOptions }   colSpan={1} />
                        </GroupItem>
                    </Form>
                    {   validationError && <p className = 'apiError'> { validationError  } </p>  }
                </form>
            </MyPopup>
            <MyPopup   
                caption='Confirmar'
                visible = { showDeleteConfirmation }
                onHidden={ () => setShowDeleteConfirmation(false) }
                width = { 'auto' }       
                height={ 'auto' }
                >
                    <p>Desea eliminar al Fraccionamiento ?</p> 
                <ToolbarItem 
                    widget = 'dxButton'
                    toolbar = 'bottom'
                    location = 'center'
                    options = { 
                        {
                            text: 'Aceptar',
                            onClick: () => deleteHandler({ confirmed: true })
                        }
                    }
                />
            </MyPopup>
        </>    
    )
}
export default SectionForm;
import React, { useState, useRef, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Form, {
  Item,
  Label,
  ButtonItem,
  ButtonOptions,
  RequiredRule,
  CustomRule,
} from 'devextreme-react/form';
import LoadIndicator from 'devextreme-react/load-indicator';
import { myNotify } from '../../ui/myNotify'

import { changePassword } from '../../api/auth';

export default function ChangePasswordForm() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const formData = useRef({ password: '' });
  const { token } = useParams();

  const onSubmit = useCallback(async (e) => {
    e.preventDefault();
    const { password } = formData.current;
    setLoading(true);

    //  ReactJS router adds ["] at the end, so lets delete it
    let tokenGoodFormat = token.replace("\"", "");
    const result = await changePassword(tokenGoodFormat, password);
    setLoading(false);

    if (result.isOk) {
      navigate('/login');
    } else {
      myNotify(result.message, 'error');
    }
  }, [ navigate ]);

  const confirmPassword = useCallback(
    ({ value }) => value === formData.current.password,
    []
  );

  return (
    <form onSubmit={onSubmit}>
      <Form formData={formData.current} disabled={loading}>
        <Item
          dataField={'password'}
          editorType={'dxTextBox'}
          editorOptions={passwordEditorOptions}
        >
          <RequiredRule message="Se requiere contraseña" />
          <Label visible={false} />
        </Item>
        <Item
          dataField={'confirmedPassword'}
          editorType={'dxTextBox'}
          editorOptions={confirmedPasswordEditorOptions}
        >
          <RequiredRule message="Se requiere conformación de contraseña" />
          <CustomRule
            message={'Contraseñas no son iguales'}
            validationCallback={confirmPassword}
          />
          <Label visible={false} />
        </Item>
        <ButtonItem>
          <ButtonOptions
            width={'100%'}
            type={'default'}
            useSubmitBehavior={true}
          >
            <span className="dx-button-text">
              {
                loading
                  ? <LoadIndicator width={'24px'} height={'24px'} visible={true} />
                  : 'Actualizar'
              }
            </span>
          </ButtonOptions>
        </ButtonItem>
      </Form>
    </form>
  );
}

const passwordEditorOptions           = { stylingMode: 'filled', placeholder: 'Nueva', mode: 'password' };
const confirmedPasswordEditorOptions  = { stylingMode: 'filled', placeholder: 'Confirmar', mode: 'password' };

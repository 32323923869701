import React, { useEffect, 
                useRef, 
                useState }          from 'react'
import { useParams, useNavigate }   from 'react-router-dom'
import DataGrid, {  Column, 
                    Editing, 
                    Pager, 
                    Paging }        from 'devextreme-react/data-grid';

import AccountItemForm              from './AccountStatementForm';
import { httpGet, httpPut }         from '../../api/methods';
import { myNotify}                  from '../../ui/myNotify';
import { homeInfo }                 from '../../utils/toFormat'
import { DateTime }                 from 'luxon';
import DatagridHeader from '../../components/datagrid-header/DatagridHeader';
import './accountStatement.scss'
import AmountBox from '../../components/amount-box/AmountBox';
import Payment from '../../components/payment/Payment';

const AccountReport = () => {
    const navigate = useNavigate();
    const [account, setAccount] = useState(null)
    const [showAccountItemForm, setShowAccountItemForm] = useState(false);
    const [showHomeAccountItemForm, setShowHomeAccountItemForm] = useState(false);
    const [startDate, setStartDate] = useState(DateTime.now().startOf('month').toMillis());
    const [endDate, setEndDate] = useState(DateTime.now().endOf('month').toMillis());
    const selectedItem = useRef(null);
    const accountInfo = useRef('');
    const { id } = useParams();

    useEffect(() => {
        //  Start on first and last day of the month
        if (id) {
            getAccountStatement();
        }
    }, [ showAccountItemForm, startDate, endDate ]);

    const getAccountStatement = () => {
        httpGet('accounts/' + id + '/statement?startDate=' + startDate + "&endDate=" + endDate ).then( res => {
            if (res.isOk) {
                let acc = res.data;                   
                let info = 'Cuenta';
                if (acc.type === 'Bancaria')    info += ' ' + acc.bank + ' #' + acc.number;
                else                            info += ' Efectivo ';
                accountInfo.current = info;
                setAccount(acc);
            }
        });
    }
    const onSelectionChangedHandler = (e) => {  selectedItem.current = e.row.data;  setShowAccountItemForm(false); }
    const addAccountItemHandler = () => {       selectedItem.current = null;        setShowAccountItemForm(true);  }  

    const editAccountItemHandler = () => {
        if (selectedItem.current !== null)        {
            if (selectedItem.current.numType === 4 || selectedItem.current.numType === 5) 
                setShowHomeAccountItemForm(true);
            else                            
                setShowAccountItemForm(true);

            return;
        }
        myNotify('Selecciona Movimiento para Editar', 'error');       
    }
   
    const calculateDescriptionHandler = (e) => {
        let description = ''
        //  Pago: Servicio
        if (e.numType === 11) {
            if (e.supplier) description += e.supplier.name;
            if (e.reference) description += " - [ " + e.reference + " ]";
        }
        //  Pago: Credito           ... Pago a cuenta de residencia
        else if (e.numType === 12) {
            if (e.homeAccountItem?.home) {
                description += homeInfo(e.homeAccountItem.home);
                if (e.reference) 
                    description += " - [ " + e.reference + " ]";
            }
        }
        else if (e.numType === 1) {
            description = homeInfo(e.homeAccountItem);
        }
        else if (e.numType === 3 || e.numType === 4) {
            if (e.homeAccountItem?.home) {
                description += homeInfo(e.homeAccountItem.home);
            }
        }
        else {
            if (e.reference) description += e.reference;
        }
        return description;
    }

    const calculateAmountHandler = (value) => {
        if (Math.abs(value) > 0.001 )
            return value; 
    }
    const toggleConcilatedHandler = (itemId) => {
        if (itemId !== null)        {
            //  toggle isReconciled Field
            httpPut('accounts/items/' + itemId + '/toggleReconcilation', {}).then( () => {
                getAccountStatement();
            })
            return;
        }
    }
    const closeHandler = () => {
        navigate('/accounts/')
    }
    return (
        account && <React.Fragment>
            <div className={'content-block'} >
                <div className={'dx-card responsive-paddings'} style={{ position: 'relative', boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)', paddingBottom: '15px'  }}>
                    <DatagridHeader title={ { banner: accountInfo.current, line1: account.beneficiary } }
                                    navs = { [  { icon: 'back',  hint: 'Regresar a Lista de Cuentas', onClick: closeHandler }, 
                                                { caption: '+ Movimiento',  width: 150,  onClick: addAccountItemHandler },
                                                { dateBox: true, label: 'Desde', defaultValue: startDate, max: endDate,   onValueChanged: (value) => setStartDate(value)  },
                                                { dateBox: true, label: 'Hasta', defaultValue: endDate,   min: startDate, onValueChanged: (value) => setEndDate(value)  },
                                            ] } 
                    />
                    <DataGrid 
                            
                            keyExpr={'id'}
                            className={'dx-card wide-card'}
                            dataSource={ account.items }
                            columnMinWidth={50}
                            columnAutoWidth={true}                       
                            onFocusedRowChanged={ onSelectionChangedHandler }
                            onRowDblClick={ editAccountItemHandler }  
                            onRowUpdated={ ({ key }) => toggleConcilatedHandler(key) }
                            sorting={ { mode: "none" }}
                        >
                            <Editing                             
                                mode="cell"
                                allowUpdating={ true }
                            />
                            <Paging defaultPageSize = { 20 } />
                            <Pager showPageSizeSelector={true} showInfo={true} />
                            <Column allowEditing={ false } dataField={'id'}           visible={false} />
                            <Column allowEditing={ false } dataField={'date'}         caption={'Fecha'}        width={ 110 }   dataType="date"  alignment="right"  />
                            <Column allowEditing={ false } dataField={'type'}         caption={'Tipo'}         width={ 200 }   hidingPriority={1}/>
                            <Column allowEditing={ false } dataField={'description'}  caption={'Descripción'}  calculateCellValue={ calculateDescriptionHandler }/>
                            <Column allowEditing={ false } dataField={'deposit'}      caption={'Deposito'}     width={ 110 }   format={{ type: 'currency', precision: 2}} calculateCellValue={ e => calculateAmountHandler(e.deposit) }/>
                            <Column allowEditing={ false } dataField={'withdraw'}     caption={'Cargo'}        width={ 110 }   format={{ type: 'currency', precision: 2}} calculateCellValue={ e => calculateAmountHandler(e.withdraw) }/>
                            <Column allowEditing={ false } dataField={'balance'}      caption={'Saldo'}        width={ 150 }   format={{ type: 'currency', precision: 2}} />
                            <Column allowEditing={ true }  dataField={'isReconciled'}  caption={'C'}            width={ 25 }    
                                                           visible={ account.type === 'Bancaria' && account.allowToConciliate }    
                                                           alignment="center"  
                            />
                    </DataGrid>
                    <div className="summary">
                        { account.allowToConciliate && Math.abs(account.amountToConciliate) > 0.01 && 
                        <AmountBox caption='Por Conciliar:' value = { account.amountToConciliate } width={ 125 } /> }
                        <AmountBox caption='Balance:'       currency = { account.balance } /> 
                    </div>
                    { showAccountItemForm && <AccountItemForm   item = { selectedItem.current } 
                                                                accountId={ id }  
                                                                accountType={ account.type }  
                                                                onHidden={ () => setShowAccountItemForm(false) } />} 

                    { showHomeAccountItemForm && <Payment id = { selectedItem.current.homeAccountItemId } 
                                                                onHidden={ () => setShowHomeAccountItemForm(false) } />} 
                </div>
            </div>
        </React.Fragment>
    )
}
export default AccountReport;
import React from 'react'
import { ResetPasswordForm } from '../../components';
import { SingleCard } from '../../layouts';
import banner               from './images/banner.jpg'
import './resetPassword.scss'
import { Popup } from 'devextreme-react';

const Login = () => {
    let desc = "Proveer nombre de usuario o correo electrónico registrado. Se enviará por correo una liga para reasignar una contraseña nueva."; 
    return (
        <React.Fragment>
            <div className={'content-block'} style = { { margin: "auto" }} >
                <div className={'dx-card responsive-paddings'} style={{ position: 'relative', boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)' }}>
                <h2>Residencial Pro </h2>
                <img src={ banner } alt='banner' style = {{ width: "100%", height: 300, borderRadius: 5, border: '1px solid gray', boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)', opacity: 0.75 } } />
                <h4>Administración y Control de Acceso</h4>
                <SingleCard title="Reasignar Contraseña" description={ desc }>        
                    <ResetPasswordForm />         
                </SingleCard> 
                </div>
            </div>
        </React.Fragment>
    )
}  


export default Login;
import React, { useEffect, 
    useRef, 
    useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import DataGrid, {  Column, 
        Pager, 
        Paging } from 'devextreme-react/data-grid';
import { httpGet } from '../../api/methods';
import { DateTime } from 'luxon';
import { timeFormat } from '../../utils/toFormat';
import DatagridHeader from '../../components/datagrid-header/DatagridHeader';
import AmountBox from '../../components/amount-box/AmountBox';
import './supplierReport.scss'

const FeeReport = () => {
    const navigate = useNavigate();
    const [supplier, setSupplier] = useState(null)
    const [startDate, setStartDate] = useState(DateTime.now().startOf('month').toMillis());
    const [endDate, setEndDate] = useState(DateTime.now().endOf('month').toMillis());
    const selectedItem = useRef(null);
    const { id } = useParams();

    useEffect(() => {
        //  Start on first and last day of the month
        if (id) {
            getReport();
        }
    }, [ startDate, endDate ]);

    const getReport = () => {
        let url = 'suppliers/' + id + '/report' + '?startDate=' + startDate + "&endDate=" + endDate;;
        httpGet(url).then( res => {            
            if (res.isOk) {
                setSupplier(res.data);
            }
        });
    }
    const onSelectionChangedHandler = (e) => {  selectedItem.current = e.row.data;   }

    const calculateAccountHandler = (e) => {
        if (!e.account) return '- - -';
        let info = e.account.bank;
        if (e.account.type !== 'Bancaria')
            info = 'Efectivo';
        return info;
    }
    const calculateBeneficiaryHandler = (e) => {
        if (!e.account) return '- - -';
        return  e.account.beneficiary;
    }
    const closeHandler = () => {
        navigate('/suppliers/');
    }
    const onRowPreparedHandler = e => {
        if (e.rowType === 'data' && e.data.numType === 1 && !e.data.isFeePaid )
            e.rowElement.style.color = "red";
    }
    return (
        supplier && <React.Fragment>
            <div className={'content-block'} >
                <div className={'dx-card responsive-paddings'} style={{ position: 'relative', boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)' }}>
                    <DatagridHeader title={ {   banner: supplier.name,  
                                                line1: "Reporte de Movimientos"
                                            } }
                                    imageName='suppliers'
                                    navs = { [ { icon: 'back',  hint: 'Regresar a Lista de Proveedores',  onClick: closeHandler },
                                                { dateBox: true, label: 'Desde', defaultValue: startDate, max: endDate,   onValueChanged: (value) => setStartDate(value)  },
                                                { dateBox: true, label: 'Hasta', defaultValue: endDate,   min: startDate, onValueChanged: (value) => setEndDate(value)  },
                                 ] } 
                    />
                    <DataGrid 
                            keyExpr={'id'}
                            className={'dx-card wide-card'}
                            dataSource={ supplier.accountItems }
                            columnMinWidth={50}
                            columnAutoWidth={true}                       
                            onFocusedRowChanged={ onSelectionChangedHandler }
                            sorting={ { mode: "none" }}
                            onRowPrepared={ e => onRowPreparedHandler(e) }
                        >
                        <Paging defaultPageSize = { 20 } />
                        <Pager showPageSizeSelector={true} showInfo={true} />
                        <Column dataField={'id'}        visible={false} />
                        <Column dataField={'addDate'}   caption={'Fecha'}       width={ 110 }   dataType="date"  alignment="right"  />
                        <Column dataField={'addDate'}   caption={'Hora'}        width={ 110 }   calculateCellValue={ e => timeFormat(e.addDate) } alignment="right"  />
                        <Column                         caption={'Cuenta'}      width={ 150 }   calculateCellValue={ calculateAccountHandler }/>
                        <Column                         caption={'Beneficiario'}                calculateCellValue={ calculateBeneficiaryHandler }/>
                        <Column                         caption={'Usuario'}     width={ 250 }   calculateCellValue = { e => { return e.addUser.fName + ' ' + e.addUser.lName }}  />
                        <Column dataField={'amount'}    caption={'Pago'}        width={ 110 }   format={{ type: 'currency', precision: 2}}  alignment="right"  />
                    </DataGrid>
                    <div className="summary"> 
                        <AmountBox caption='Pagos:' currency = { supplier.payments } /> 
                    </div>


                </div>
            </div>
        </React.Fragment>
    )
}
export default FeeReport;
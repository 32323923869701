import React, { useEffect, useRef, useState } 
                            from 'react'
import DataGrid, { Column, HeaderFilter, Pager, Paging, RequiredRule } 
                            from 'devextreme-react/data-grid';
import { useNavigate }      from 'react-router-dom'
import { getStore }         from '../../api/stores';
import { myNotify}          from '../../ui/myNotify';
import HomeForm from './HomeForm';
import DatagridHeader from '../../components/datagrid-header/DatagridHeader';
import { SelectBox } from 'devextreme-react';
import { httpGet } from '../../api/methods';

const Homes = () => {
    const navigate = useNavigate();
    const [editId, setEditId] = useState(null);
    const selectedId = useRef(null);

    const addHandler = () => {  setEditId('new');    }
    const editHandler = () => {
        if (selectedId.current) {
            setEditId(selectedId.current);
            return;
        }
        myNotify('Seleccionar residencia para editar', 'error');
    }
    const reportHandler = () => {
        if (selectedId.current)        {
            navigate('/homes/' + selectedId.current)
            return;
        }
        myNotify('Seleccionar residencia para ver reporte', 'error');       
    }
    return (
        <React.Fragment>
            <div className={'content-block'} >
                <div className={'dx-card responsive-paddings'} style={{ position: 'relative', boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)' }}>
                    <DatagridHeader title = { { banner: 'Residencias' } }  imageName='homes'
                                    navs = { [  { icon: 'doc',  hint: 'Mostrar Reporte',    onClick: reportHandler }, 
                                                { icon: 'edit', hint: 'Editar Residencia',  onClick: editHandler },
                                                { icon: 'add',  hint: 'Agregar Residencia', onClick: addHandler }
                                           ] } 
                    />                    
                    <DataGrid 
                            id='datagrid'
                            keyExpr={ 'id' }
                            className={'dx-card wide-card'}
                            dataSource={ getStore('homes') }
                            columnMinWidth={50}
                            columnAutoWidth={true}                       
                            onFocusedRowChanged={ (e) => { selectedId.current = e.row.data.id; } }
                            onRowDblClick={ reportHandler }    
                        >
                        <HeaderFilter 
                            visible={true} 
                            allowSelectAll={ false }
                            allowSearch={ true }
                            width={350}
                            texts={ {
                                cancel: 'Cancelar',
                                ok: 'Aceptar'
                            }}                        
                        />
                        <Paging 
                            defaultPageSize = { 20 } 
                        />
                        <Pager showPageSizeSelector={true} showInfo={true} />
                        <Column dataField={'id'}                    caption={'ID'}              visible={false}  />
                        <Column dataField={'section.name'}          caption={'Fraccionamiento'} allowFiltering={ true }  />
                        <Column                                     caption={'Dirección'}       allowFiltering={ false }    calculateCellValue={ e => ( e.street + ' #' + e.number )} />
                        <Column dataField={'showFamily'}            caption={'Mostrar'}         allowFiltering={ false }    width={ 75 }       dataType={'boolean'} />
                        <Column dataField={'familyName'}            caption={'Familia'}         allowFiltering={ true }     allowSearch={ true } />
                        <Column dataField={'accessTags'}            caption={'AccesoTags'}      allowFiltering={ false }    width={ 100 }        dataType={'boolean'} />
                        <Column dataField={'accessTagsExpireDate'}  caption={'Tags Expiran'}    allowFiltering={ false }    width={ 110 }       dataType="date"  alignment="right"  />
                        <Column dataField={'balance'}               caption={'Balance'}         allowFiltering={ false }    width={ 125 }   />
                        
                    </DataGrid>
                    { editId &&         <HomeForm  id = { editId } onHidden={ () => setEditId(null) } />}
                </div>
            </div>
        </React.Fragment>
    )
}

export default Homes;
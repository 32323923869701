import React from 'react';
import './home.scss';

export default function Home() {
  return (
    <React.Fragment>
      <h2 className={'content-block'}>Residencial Pro</h2>
      <div className={'content-block'}>
        <div className={'dx-card responsive-paddings'} style= {{ color: 'white'}}>

          <div className="landing">
            <img src= {require('./images/landing.jpg') } style = {{ width: '100%', height: '100%' }} />
          </div>


        </div>
      </div>
    </React.Fragment>
)}
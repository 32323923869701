import React, { useEffect, useRef, useState } from 'react'
import { ToolbarItem } from 'devextreme-react/popup';
import Form, { SimpleItem, GroupItem, TabbedItem, TabPanelOptions, Tab, Label, ButtonItem } from 'devextreme-react/form';
import { TagBox } from 'devextreme-react';
import { httpDelete, httpGet, httpPost, httpPut } from '../../api/methods';
import MyPopup from '../../components/popup/Popup';
import './accounts.scss';


const AccountForm = ({ id, onHidden }) => {
    const [formData, setFormData] = useState(null);
    const [type, setType] = useState(null);
    const [validationError, setValidationError] = useState(null);
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [sections, setSections] = useState([])

    const selectedSectionIds = useRef([]);

    useEffect( () => {
        httpGet("/sections/").then(sections => {
            if (sections.isOk) {
                setSections(sections.data);
            }

            if (id === "new") {
                //  Adding New
                setFormData({
                    bank: '',
                    number: '',
                    allowToConciliate: true,
                })
                setType('bancaria');
            }
            else {
                //  Getting
                httpGet("/accounts/" + id).then( res => {
                    let acc = res.data;
                    setFormData({
                        bank: acc.bank,
                        number: acc.number,
                        beneficiary: acc.beneficiary,
                        clabe: acc.clabe,
                        acceptCC: acc.acceptCC, 
                        initialBalance: acc.initialBalance,
                        allowToConciliate: acc.allowToConciliate,
                        ccChargeResident: acc.ccChargeResident,
                        ccChargePerc: acc.ccChargePerc,
                        ccChargeFixed: acc.ccChargeFixed,
                        ccChargeVAT: acc.ccChargeVAT,
                        gateway: acc.gateway,
                    });
                    res.data.sections.forEach(s => {
                        selectedSectionIds.current.push(s.id);
                    });
                    setType(res.data.type.toLowerCase());
                    
                }).catch( e => console.log(e) );
            }
        }).catch( e => console.log(e) );
    }, [ id ] )

    const submitHandler = (e) => {
        e.preventDefault();

        //  Validate
        saveBankAccount().then( res => {
            if (res.isOk)   closeForm();
            else            setValidationError(res.message)            
        }).catch( e => console.log(e) );
    }

    const saveBankAccount = () => {
        return new Promise((resolve, reject) => {
            let body = { ...formData, type, sectionIds: selectedSectionIds.current };
            if (id === "new") {
                //  posting new
                httpPost("/accounts/", body).then( res => {
                    resolve(res);
                }).catch( e => console.log(e) );
            } else {
                //  updating
                httpPut("/accounts/" + id, body).then( res => {
                    resolve(res);
                }).catch( e => console.log(e) );
            }    
        })
    }
    const onHidingHandler = () => {
        closeForm();
    }

    const closeForm = () => {
        if (onHidden) 
            onHidden();
    }

    const deleteHandler = ({ confirmed }) => {
        if (!confirmed) {
            setShowDeleteConfirmation(true);
            return;
        }

        //  Delete account
        httpDelete("/accounts/" + id).then( res => {
            if (res.isOk)
                onHidingHandler();
            else
                console.log(res);
        })
    }

    const acceptButtonOptions = {
        text: id === "new"  
              ? "Agregar"
              : "Actualizar",
        type: 'default',
        useSubmitBehavior: true,  
    }
    const deleteButtonOptions = {
        text: "Eliminar",
        type: 'danger-outline',
        useSubmitBehavior: false,   
        onClick: () => deleteHandler({ confirmed: false })
    }

	const currencyOptions = {
        format: { type: 'currency', precision: 2, },
    }

	const percentageOptions = {
        format: { type: '##.## %', precision: 2 },
    }

    const typeOptions = {
        items:  [   { type: "bancaria", text: "Bancaria" },
                    { type: "efectivo", text: "Efectivo" },
                ],
        value: type,
        placeholder: 'Seleccionar. . .',
        valueExpr: 'type',
        displayExpr: 'text',
        onValueChanged: ({ value }) => { setType(prev => value) }  
        
    }

	const Sections = () => {
        return (
            <div style = {{ width: "auto" }}> 
                <Label text={ "Nombre de Fraccionamiento" } />
                <TagBox
                    id = "selectedSections"
                    items={ sections }
                    placeholder='Seleccionar...'
                    displayExpr="name"
                    valueExpr="id"
                    defaultValue={ selectedSectionIds.current }
                    onValueChanged={ e => selectedSectionIds.current = e.value }
                    hideSelectedItems={ true }
                />
            </div>
        )
    }
    let caption = id === "new" 
                    ? "Agregar Cuenta" 
                    : "Editar Cuenta";

    caption += type === 'bancaria'
                    ? " - Bancaria"
                    : " - Efectivo"
    return (
        formData && type && <>
            <MyPopup caption = { caption } visible={ true } onHidden={ onHidden }  >  
                <form onSubmit={ submitHandler }>
                    <Form 
                        formData = { formData }
                        labelLocation='top'
                        labelMode='floating'
                        >
                        {   id === 'new' && 
                            <GroupItem colCount={3}>
                                <SimpleItem dataField="type"    label={ { text: "Tipo de Cuenta" } } colSpan={1} 
                                            editorType="dxSelectBox"
                                            editorOptions= { typeOptions }
                                />
                            </GroupItem>                       
                        }
                        {   type === 'bancaria' && 
                            <GroupItem>
                                <GroupItem colCount={ 12 }>                   
                                    <SimpleItem dataField="bank"            label={ { text: "Nombre del Banco" } }  colSpan={ 8 } isRequired={ true } />
                                    <SimpleItem dataField="number"          label={ { text: "Número de Cuenta" } }  colSpan={ 4 } isRequired={ true } />
                                    <SimpleItem dataField="beneficiary"     label={ { text: "Beneficiario" } }      colSpan={ 9 } isRequired={ true } />
                                    <SimpleItem dataField="initialBalance"  label={ { text: "Balance Inicial" } }   colSpan={ 3 }  
                                                editorType={ "dxNumberBox" } 
                                                editorOptions={ currencyOptions } />
                                </GroupItem>
                                <GroupItem >
                                    <TabbedItem>
                                        <TabPanelOptions deferRendering={false} height={ 200 }/>
                                        <Tab title = "Fraccionamientos" colCount={ 12 }>
                                            <SimpleItem colSpan={ 10 } 		label = { { text: "Asignados a la Cuenta" } } >
                                                <Sections />
                                            </SimpleItem>
                                        </Tab>
                                        <Tab title = "Pagos con Tarjeta" colCount={12}>
                                            <SimpleItem dataField="acceptCC"            colSpan ={2}     label={ { text: "Aceptar" } } editorType={ "dxCheckBox"} />       
                                            <SimpleItem dataField="clabe"               colSpan ={6}     label={ { text: "CLABE" } }         />
                                            <SimpleItem dataField="gateway"             colSpan ={3}     label={ { text: "Plataforma" } }         />
                                            <SimpleItem                                 colSpan ={1}/>
                                            <SimpleItem dataField="ccChargeResident"    colSpan ={2}     label={ { text: "Cargo a Colono" } } editorType={ "dxCheckBox"} />       
                                            <SimpleItem dataField="ccChargePerc"  label={ { text: "Cargo" } }       colSpan={ 2 }  
                                                        editorType={ "dxNumberBox" } 
                                                        editorOptions={ percentageOptions } 
                                            />
                                            <SimpleItem dataField="ccChargeFixed"  label={ { text: "Fijo" } }    colSpan={ 2 }  
                                                        editorType={ "dxNumberBox" } 
                                                        editorOptions={ currencyOptions } 
                                            />
                                            <SimpleItem dataField="ccChargeVAT"  label={ { text: "IVA" } }       colSpan={ 2 }  
                                                        editorType={ "dxNumberBox" } 
                                                        editorOptions={ percentageOptions } 
                                            />
                                            
                                            

                                        </Tab>
                                        <Tab title = "Configuración" colCount={12}>
                                            <SimpleItem dataField="allowToConciliate"    colSpan ={8}     label={ { text: "Habilitar Reconciliación" } } editorType={ "dxCheckBox"} />       
                                        </Tab>
                                    </TabbedItem>
                                </GroupItem>
                            </GroupItem>
                        }
                        {   type === 'efectivo' && 
                            <GroupItem>
                                <GroupItem colCount={ 12 }>                   
                                    <SimpleItem dataField="beneficiary"     label={ { text: "Beneficiario" } }      colSpan={ 9 } isRequired={ true } />
                                    <SimpleItem dataField="initialBalance"  label={ { text: "Balance Inicial" } }   colSpan={ 3 }  
                                                editorType={ "dxNumberBox" } 
                                                editorOptions={ currencyOptions } />
                                </GroupItem>
                                
                                <GroupItem >
                                    <TabbedItem>
                                        <TabPanelOptions deferRendering={false} height={ 140 }/>
                                        <Tab title = "Fraccionamientos" colCount={ 12 }>
                                            <SimpleItem colSpan={ 10 } 		label = { { text: "Asignados a la Cuenta" } } >
                                                <Sections />
                                            </SimpleItem>
                                        </Tab>
                                    </TabbedItem>
                                </GroupItem>
                            </GroupItem>
                        }

                        <GroupItem colCount={2}>
                            <SimpleItem colSpan={2}> <hr style = {{ margin: '0px', height: '1px', backgroundColor: '#ccc', border: 'none' }} /> </SimpleItem>
                            {   id === "new"
                                ? <SimpleItem colSpan={1} ></SimpleItem>
                                : <ButtonItem horizontalAlignment="left" buttonOptions={ deleteButtonOptions } colSpan={1}/>
                            }
                            <ButtonItem horizontalAlignment="right" buttonOptions={ acceptButtonOptions }   colSpan={1} />
                        </GroupItem>
                    </Form>

                    {   validationError && <p className = "apiError"> { validationError  } </p>  }
                </form>
            </MyPopup>

            <MyPopup   
                caption='Confirmar'
                visible = { showDeleteConfirmation }
                onHidden={ () => setShowDeleteConfirmation(false) }
                width = { "auto" }       
                height={ "auto" }
                >
                    <p>Desea eliminar la Cuenta Bancaria ?</p> 
                <ToolbarItem 
                    widget = "dxButton"
                    toolbar = "bottom"
                    location = "center"
                    options = { 
                        {
                            text: "Aceptar",
                            onClick: () => deleteHandler({ confirmed: true })
                        }
                    }
                />
            </MyPopup>
        </>    
    )
}
export default AccountForm;
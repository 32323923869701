import React, { useEffect, useState } from 'react'
import './fees.scss';
import { ToolbarItem } from 'devextreme-react/popup';
import Form, { SimpleItem, GroupItem, ButtonItem } from 'devextreme-react/form';
import { httpDelete, httpGet, httpPost, httpPut } from '../../api/methods';
import MyPopup from '../../components/popup/Popup';

const FeeForm = ({ id, onHidden }) => {
    const [formData, setFormData] = useState(null);
    const [caption, setCaption] = useState(null);
    const [validationError, setValidationError] = useState(null);
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [sections, setSections] = useState([]);
    const [bankAccounts, setBankAccounts] = useState([]);
    const [cashAccounts, setCashAccounts] = useState([]);

    useEffect( () => {
        if (id === 'new') {
            httpGet("/sections/").then(res => {
                if (res.isOk) setSections(res.data);
                //  Default Values
                setFormData({ penalty: 0, tolerance: 5 })
                setCaption("Agregar Cuota Nueva");
            }).catch( e => console.log(e) );
        }
        else {
            // get the fee object
            httpGet("/fees/" + id).then( res => {
                let fee = res.data;
                setFormData({
                    name: fee.name,
                    amount: fee.amount,
                    startDate: fee.startDate,
                    tolerance: fee.tolerance,
                    penalty: fee.penalty,
                    type: fee.type.toLowerCase(),
                    recurrency: fee.recurrency.toLowerCase(),
                    sectionId: fee.sectionId,
                    bankAccountId: fee.bankAccountId,
                    cashAccountId: fee.cashAccountId,
                });
                setSections([ res.data.section ])               
                setCaption("Actualizar Cuota - " + res.data?.section.name);
                selectSection(fee.sectionId)
            }).catch( e => console.log(e) );
        }
    }, [ id ] );

    const submitHandler = (e) => {
        e.preventDefault();
        //  Validate
        saveBankAccount().then( res => {
            if (res.isOk)   closeForm();
            else            setValidationError(res.message)            
        }).catch( e => console.log(e) );
    }
    const saveBankAccount = () => {
        return new Promise((resolve, reject) => {           
            if (id === "new") {
                //  posting new
                httpPost("/fees/", { ...formData }).then( res => {
                    resolve(res);
                }).catch( e => console.log(e) );
            } else {
                //  updating
                httpPut("/fees/" + id, { ...formData }).then( res => {
                    resolve(res);
                }).catch( e => console.log(e) ); 
            }    
        })
    }
    const onHidingHandler = () => {
        closeForm();
    }

    const closeForm = () => {
        if (onHidden) 
            onHidden();
    }

    const deleteHandler = ({ confirmed }) => {
        if (!confirmed) {
            setShowDeleteConfirmation(true);
            return;
        }

        //  Delete account
        httpDelete("/fees/" + id).then( res => {
            if (res.isOk)
                onHidingHandler();
            else
                console.log(res);
        })
    }

    const selectSection = (sectionId) => {
        setFormData(prev => ({ ...prev, sectionId }))

        //  update Accounts
        httpGet('accounts?sectionId=' + sectionId).then( res => {
            if (res.isOk) {
                let accounts = res.data;
                //  Filter Bank Accounts
                let bankAccounts = []
                accounts.filter(acc => acc.type === 'Bancaria').forEach(acc => {
                    bankAccounts.push({ id: acc.id, name: acc.beneficiary + ' [' + acc.bank + ']' })
                })
                setBankAccounts(bankAccounts);
                // if (bankAccounts.length === 1)
                //     setFormData(prev => ({ ...prev, bankAccountId: bankAccounts[0].id }));

                //  Filter Cash Accounts
                let cashAccounts = []                
                accounts.filter(acc => acc.type === 'Efectivo').forEach(acc => {
                    cashAccounts.push({ id: acc.id, name: acc.beneficiary  })
                })
                setCashAccounts(cashAccounts);
                // if (cashAccounts.length === 1)
                //     setFormData(prev => ({ ...prev, cashAccountId: cashAccounts[0].id }));
            }
        })
    }

    const acceptButtonOptions = {
        text: id === "new"  
              ? "Agregar"
              : "Actualizar",
        type: 'default',
        useSubmitBehavior: true,  
    }
    const deleteButtonOptions = {
        text: "Eliminar",
        type: 'danger-outline',
        useSubmitBehavior: false,   
        onClick: () => deleteHandler({ confirmed: false })
    }

    const sectionOptions = {
        visible: id === 'new',
        items: sections,
        valueExpr: "id",
        displayExpr: "name",
        onValueChanged: ({value}) => selectSection(value)
    }

	const balanceOptions = {
        format: { type: 'currency', precision: 2, },
    }

	const typeOptions = {
        items: [ 
            {  type: 'recurrente',      text: "Recurrente" },
            {  type: 'extraordinaria',  text: "Extra-Ordinaria" },
            {  type: 'cooperacion',     text: "Cooperación" },
            {  type: 'credito',         text: "Credito" },
            ],
        valueExpr: "type",
        displayExpr: 'text',
        onValueChanged: ({value}) => setFormData(prev => ({ ...prev, type: value }))
    }
	const recurrencyOptions = {
        visible: formData?.type === 'recurrente',
        items: [ 
            {  recurrency: 'mensual',       text: "Mensual" },
            {  recurrency: 'anual',         text: "Anual" },
            {  recurrency: 'semanal',       text: "Semanal" },
            {  recurrency: 'quincenal',     text: "Quincenal" },
            {  recurrency: 'catorcenal',    text: "Catorcenal" },
            ],
        valueExpr: "recurrency",
        displayExpr: 'text',
        onValueChanged: ({value}) => setFormData(prev => ({ ...prev, recurrency: value }))
    }
	const startDateOptions = {
        min: Date.now() - 30 * 24 * 60 * 60 * 1000,         //  no earlier than 30 days ago
        max: Date.now() + 365 * 24 * 60 * 60 * 1000,        //  2 year from now
        displayFormat: "EEEE, d of MMM, yyyy 02:00a" 
    }

    const toleranceOptions = {
        visible: formData?.type === 'recurrente' || formData?.type === 'extraordinaria',
        min: 0,
        max: 365
    }
	const penaltyOptions = {
        format: { type: 'currency', precision: 2 },
        visible: formData?.type === 'recurrente' || formData?.type === 'extraordinaria',
        min: 0,        
    }

    const bankAccountOptions = {
        items: bankAccounts,
        readOnly: !formData?.sectionId,
        valueExpr: "id",
        displayExpr: "name",
        placeholder: 'Seleccionar...',
        noDataText: 'No existen cuentas bancarias asignadas',
        onValueChanged: ({value}) => setFormData(prev => ({ ...prev, bankAccountId: value }))
    }
    const cashAccountOptions = {
        items: cashAccounts,
        readOnly: !formData?.sectionId,
        valueExpr: "id",
        displayExpr: "name",
        placeholder: 'Seleccionar...',
        noDataText: 'No existen cuentas de efectivo asignadas',
        onValueChanged: ({value}) => setFormData(prev => ({ ...prev, cashAccountId: value }))
    }
    return (
        formData && <>
            <MyPopup caption = { caption } visible={ true } onHidden={ onHidden }  >  
                <form onSubmit={ submitHandler }>
                    <Form 
                        formData = { formData }
                        labelLocation='top'
                        labelMode='floating'
                        >
                        <GroupItem colCount={ 12 }>                   
                            <SimpleItem dataField="sectionId"     label={ { text: "Fraccionamiento" } }   colSpan={ 12 } isRequired={ id === 'new' }  
                                        editorType={ "dxSelectBox" } 
                                        editorOptions={ sectionOptions } />                               

                            <SimpleItem dataField="name"        label={ { text: "Nombre de la Cuota" } }  colSpan={ 9 } isRequired={ true } />
                            <SimpleItem dataField="amount"      label={ { text: "Cantidad" } }   colSpan={ 3 }  
                                        editorType={ "dxNumberBox" } 
                                        editorOptions={ balanceOptions } />

                            <SimpleItem dataField="type"       label={ { text: "Tipo" } }      colSpan={3} 
                                            editorType={ "dxSelectBox" } 
                                            editorOptions={ typeOptions } />
                            <SimpleItem dataField="startDate"       label={ { text: "Fecha de Aplicación" } }   colSpan={ 6 }  
                                            editorType={ "dxDateBox" } 
                                            editorOptions={ startDateOptions } />
                            <SimpleItem dataField="penalty"      label={ { text: "Recargo" } } colSpan={3}
                                            editorType={ "dxNumberBox" } 
                                            editorOptions={ penaltyOptions } />


                            <SimpleItem dataField="recurrency"   label={ { text: "Periodo" } } colSpan={3}
                                            editorType={ "dxSelectBox" } 
                                            editorOptions={ recurrencyOptions } />
                            <SimpleItem colSpan={6}/>
                            <SimpleItem dataField="tolerance"      label={ { text: "Dias Tolerancia" } } colSpan={3}
                                            editorType={ "dxNumberBox" } 
                                            editorOptions={ toleranceOptions } />

                            <SimpleItem colSpan={4}/>
                        </GroupItem>

                        <GroupItem caption={"Depositar a:"} visible={ formData?.type !== 'credito' } colCount={2}>
                            <SimpleItem dataField="bankAccountId"     label={ { text: "Cuenta Bancaria" } }
                                        editorType={ "dxSelectBox" } 
                                        editorOptions={ bankAccountOptions } />                               
                            <SimpleItem dataField="cashAccountId"     label={ { text: "Efectivo" } }
                                        editorType={ "dxSelectBox" } 
                                        editorOptions={ cashAccountOptions } />                               
                        </GroupItem>

                        <GroupItem colCount={2}>
                            <SimpleItem colSpan={2}> <hr style = {{ margin: '0px', height: '1px', backgroundColor: '#ccc', border: 'none' }} /> </SimpleItem>
                            {   id === "new"
                                ? <SimpleItem colSpan={1} ></SimpleItem>
                                : <ButtonItem horizontalAlignment="left" buttonOptions={ deleteButtonOptions } colSpan={1}/>
                            }
                            <ButtonItem horizontalAlignment="right" buttonOptions={ acceptButtonOptions }   colSpan={1} />
                        </GroupItem>
                    </Form>

                    {   validationError && <p className = "apiError"> { validationError  } </p>  }
                </form>
            </MyPopup>

            <MyPopup   
                caption='Confirmar'
                visible = { showDeleteConfirmation }
                onHidden={ () => setShowDeleteConfirmation(false) }
                width = { "auto" }       
                height={ "auto" }
                >
                    <p>Desea eliminar la Cuota ?</p> 
                <ToolbarItem 
                    widget = "dxButton"
                    toolbar = "bottom"
                    location = "center"
                    options = { 
                        {
                            text: "Aceptar",
                            onClick: () => deleteHandler({ confirmed: true })
                        }
                    }
                />
            </MyPopup>
        </>    
    )
}
export default FeeForm;
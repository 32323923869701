import React, { useEffect, useRef, useState } from 'react'
import Form, { SimpleItem, GroupItem, ButtonItem, EmptyItem } from 'devextreme-react/form';
import { httpDelete, httpGet, httpPost, httpPut } from '../../api/methods';
import MyPopup from '../popup/Popup';
import { currency } from '../../utils/toFormat';
import MobilePicture from '../mobile-picture/MobilePicture';
import './payment.scss';

const PaymentRejection = ({ payment, onHidden }) => {
    const [ formData, setFormData ] = useState({ });
    const [validationError, setValidationError] = useState(null);
    
    useEffect( () => {
        let data = {
            applyWireProofIntent:       payment.home.applyWireProofIntent,
            applyWireProofIntentDate:   payment.home.applyWireProofIntentDate,
            }   
            setFormData(data);
    }, [  ] );

    const rejectHandler = () => {
        httpPut('payments/' + payment.id + '/rejectIntent').then( res => {
            if (res.isOk) {
                if (onHidden) onHidden();
            } else
                setValidationError(res.message);       
        }).catch( err => console.log(err))
    }

    const rejectButtonOptions = {
        text: !payment.approved ? 'Rechazar' : 'Cancelar',
        type: 'danger',
        useSubmitBehavior: false,  
        onClick: () => rejectHandler()
    }

    const applyWireProofOptions = {
        hint:   'Al hacer pago de cuota o deposito,\n' +
                'permite pre-aprobar la cantidad indicada por colono.\n\n' + 
                'Este beneficia al colono porque se pueden liberar servicios,\nen caso que hayan estado suspendidos,\n' +
                'sin tener que esperar aprobación de administradores.\n  ',
        onValueChanged: ({value}) => setFormData(prev => ({ ...prev, applyWireProofIntent: value }))
    }
    const applyWireProofOptionsDate = {
        visible: formData?.applyWireProofIntent,
        hint:   'Si el colono no ha sido honesto con las cantidades depositadas,\n' +
                'se puede bloquear el beneficio temporalmente.\n\n' + 
                'Esta fecha indica cuando se le permitiría al colono\nla pre-aprobación de comprobantes de pago.\n  '
    }

    let caption = payment.wireProofIntent > 0.001 
            ? 'Comprobante de Pago' 
            : 'Entrega de Efectivo';

    let question = payment.approved
            ? 'Desea cancelar el pago?'
            : 'Desea rechazar el pago?'

    return (
        <>
            <MyPopup caption = { caption } visible={ true } onHidden={ onHidden } width={ 400 } showCloseButton = { false } >  
                <Form 
                    formData = { formData }
                    labelLocation = 'top'
                    labelMode = 'floating'
                    >

                    <GroupItem colCount={ 2 } cssClass={ 'rejectInfo' } >
                        {   !payment.approved && payment.wireProofIntent > 0.001 &&
                            // Show Home information
                            <GroupItem colSpan={2} colCount={10} >
                                <SimpleItem colSpan={10}>
                                    <p className='homeInfo'>
                                        { payment.home.info }
                                    </p>
                                </SimpleItem>

                                <EmptyItem colSpan={1} />
                                <SimpleItem dataField="applyWireProofIntent"        label={ { text: "Pre-aprobación Comprobantes" } }  colSpan={ 8 }   
                                            editorType={ "dxCheckBox"}
                                            editorOptions={ applyWireProofOptions } /> 
                                <EmptyItem colSpan={1} />

                                <EmptyItem colSpan={1} />
                                <SimpleItem dataField='applyWireProofIntentDate'    label={ { text: 'Aceptar desde' } }      colSpan={ 8 }   
                                            editorType={'dxDateBox'}
                                            editorOptions={ applyWireProofOptionsDate } /> 
                                <EmptyItem colSpan={1} />

                                <SimpleItem colSpan={10}> <hr style = {{ margin: '0px', height: '1px', backgroundColor: '#ccc', border: 'none' }} /> </SimpleItem>        

                            </GroupItem>
                        }

                        <SimpleItem colSpan = { 2 }>
                            <p> { question } </p>                           
                        </SimpleItem>


                    </GroupItem>    

                    <GroupItem colCount={2}>
                        <SimpleItem colSpan={2}> <hr style = {{ margin: '0px', height: '1px', backgroundColor: '#ccc', border: 'none' }} /> </SimpleItem>
                        <SimpleItem colSpan={1}/>
                        <ButtonItem horizontalAlignment='right' buttonOptions={ rejectButtonOptions } colSpan={1} />
                    </GroupItem>

                </Form>
                {   validationError && <p className = 'apiError'> { validationError  } </p>  }
            </MyPopup>
        </>    
    )
}
export default PaymentRejection;
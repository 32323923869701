import React, { useEffect, useRef, useState } from 'react'
import { ToolbarItem } from 'devextreme-react/popup';
import Form, { SimpleItem, GroupItem, ButtonItem } from 'devextreme-react/form';
import { httpDelete, httpGet, httpPost, httpPut } from '../../api/methods';
import MyPopup from '../../components/popup/Popup';
import './residents.scss';
import { CheckBox, TagBox } from 'devextreme-react';

const ResidentForm = ({ id, onHidden }) => {
    const [formData, setFormData] = useState({});
    const [homes, setHomes] = useState([]);
    const [validationError, setValidationError] = useState(null);
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const selectedHomeIds = useRef([]);
    const refKeepAdding = useRef(false);

    useEffect( () => {
        httpGet('homes').then( loadedHomes => {
            if (loadedHomes.isOk) {

                loadedHomes.data.forEach(home => {
                    let info = '[ ' + home.section.name + ' ] - ';
                    info += home.street + ' #' + home.number
                    home.info = info
                   
                });
                setHomes(loadedHomes.data);
            }

            if (id === 'new') {
                setFormData({
                    fName: '',
                    lName: '',
                    phone: '',
                    email: '',
                    workEmail: '',
                });
            } else {
                httpGet('/residents/' + id).then( res => {
                    let supplier = res.data;
                    setFormData({
                        fName: supplier.fName,
                        lName: supplier.lName,
                        phone: supplier.phone,
                        email: supplier.email,
                        workEmail: supplier.workEmail,
                    });       
                    res.data.homes.forEach(h => {
                        selectedHomeIds.current.push(h.id);
                    });                   
                }).catch( e => console.log(e) );
            }

        }).catch( e => console.log(e) );
    }, [ ] );

    const submitHandler = (e) => {
        e.preventDefault();
        //  Validate
        saveResident().then( res => {
            if (res.isOk){ 
                if (id !== 'new' || (id === 'new' && refKeepAdding.current === false)) 
                closeForm();

                //  Clear Form and keep adding
                selectedHomeIds.current = [];
                setFormData(prev => ({ ...prev,
                    fName: '',
                    lName: '',
                    phone: '',
                    email: '',
                    workEmail: '',
                }));              
            }
            else            
                setValidationError(res.message);
        }).catch( e => console.log(e) );
    }

    const saveResident = () => {
        return new Promise((resolve, reject) => {
            let body = { ...formData, homeIds: selectedHomeIds.current }
            if (id === 'new') {
                //  posting new
                httpPost('/residents/', body).then( res => {
                    resolve(res);
                }).catch( e => console.log(e) );
            } else {
                //  updating
                httpPut('/residents/' + id, body).then( res => {
                    resolve(res);
                }).catch( e => console.log(e) );
            }    
        })
    }
    const onHidingHandler = () => { closeForm(); }
    const closeForm = () => { if (onHidden) onHidden(); }
    const deleteHandler = ({ confirmed }) => {
        if (!confirmed) {
            setShowDeleteConfirmation(true);
            return;
        }
        //  Delete
        httpDelete('/residents/' + id).then( res => {
            if (res.isOk)   onHidingHandler();
            else            console.log(res);
        })
    }
    const acceptButtonOptions = {
        text: id === 'new'  
              ? 'Agregar'
              : 'Actualizar',
        type: 'default',
        useSubmitBehavior: true,  
    }
    const deleteButtonOptions = {
        text: 'Eliminar',
        type: 'danger-outline',
        useSubmitBehavior: false,   
        onClick: () => deleteHandler({ confirmed: false })
    }
    let caption = id === 'new' 
                    ? 'Agregar Colono' 
                    : 'Editar Colono: ' + formData.fName + ' ' + formData.lName;

    const Homes = () => {
        return (
            <div style = {{ width: "auto" }}> 
                <TagBox
                    items={ homes }
                    // label='Residencias'
                    placeholder='Seleccionar'
                    noDataText='...'
                    valueExpr="id"
                    displayExpr="info"
                    searchEnabled={true}                   
                    defaultValue={ selectedHomeIds.current }
                    onValueChanged={ e => selectedHomeIds.current = e.value }
                    hideSelectedItems={ true }
                />
            </div>
        )
    }

    return (
        <>
            <MyPopup caption = { caption } visible={ true } onHidden={ onHidden } width={ 800 }  >  
                <form onSubmit={ submitHandler }>
                    <Form 
                        formData = { formData }
                        labelLocation='top'
                        labelMode='floating'
                        >
                        <GroupItem colCount={6}>
                            <SimpleItem dataField='fName'           label={ { text: 'Nombre' } }                colSpan={ 3 } isRequired={ true } />
                            <SimpleItem dataField='lName'           label={ { text: 'Apellidos' } }             colSpan={ 3 } isRequired={ true } />
                            <SimpleItem dataField='email'           label={ { text: 'Correo' } }                colSpan={ 2 } />
                            <SimpleItem dataField='workEmail'       label={ { text: 'Correo (trabajo)' } }      colSpan={ 2 } />
                            <SimpleItem dataField='phone'           label={ { text: 'Teléfono' } }              colSpan={ 2 } />
                        </GroupItem>
                        <GroupItem caption={'Residencias'} >
                                <Homes />
                        </GroupItem>

                        <GroupItem colCount={2}>
                            <SimpleItem colSpan={2}> <hr style = {{ margin: '0px', height: '1px', backgroundColor: '#ccc', border: 'none' }} /> </SimpleItem>
                            {   id === 'new'
                                ?   <SimpleItem colSpan={1} >
                                        <CheckBox 
                                            text='Seguir agregando al terminar cada registro?' 
                                            hint='Ventana sigue abierta después de cada registro' 
                                            onValueChanged={ ({value}) => refKeepAdding.current = value }
                                        />
                                    </SimpleItem>
                                :   <ButtonItem horizontalAlignment='left' buttonOptions={ deleteButtonOptions } colSpan={1}/>
                            }
                            <ButtonItem horizontalAlignment='right' buttonOptions={ acceptButtonOptions }   colSpan={1} />
                        </GroupItem>
                    </Form>
                    {   validationError && <p className = 'apiError'> { validationError  } </p>  }
                </form>
            </MyPopup>
            <MyPopup   
                caption='Confirmar'
                visible = { showDeleteConfirmation }
                onHidden={ () => setShowDeleteConfirmation(false) }
                width = { 'auto' }       
                height={ 'auto' }
                >
                    <p>Desea eliminar colono ?</p> 
                <ToolbarItem 
                    widget = 'dxButton'
                    toolbar = 'bottom'
                    location = 'center'
                    options = { 
                        {
                            text: 'Aceptar',
                            onClick: () => deleteHandler({ confirmed: true })
                        }
                    }
                />
            </MyPopup>
        </>    
    )
}
export default ResidentForm;
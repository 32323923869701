import React, { useState, useEffect } from 'react'
import ResponsiveBox, { Row, Col, Item, Location, } from 'devextreme-react/responsive-box';
import { DateTime } from 'luxon'
import './visits.scss';

function VisitDetail({ visit }) {    
     const [visitDetail, setVisitDetail] = useState(visit);
     useEffect( () => {
          let accessInfoIn    = accessInfo(visit.addUser, visit.inDate);
          let accessInfoExit  = accessInfo(visit.exitUser, visit.outDate);
          setVisitDetail({ ...visit, accessInfoIn, accessInfoExit });
     }, [] );
     const accessInfo = (user, date) => {
          //   Validate
          if (!date) return ""
          let dtTime     = DateTime.fromISO(date).toLocaleString(DateTime.TIME_SIMPLE);
          let dtInDate   = DateTime.fromISO(visit.inDate).toLocaleString(DateTime.DATE_SHORT);       // this is to compare to entrance day
          let dtDate     = DateTime.fromISO(date).toLocaleString(DateTime.DATE_SHORT);
          dtDate = dtInDate != dtDate ? dtDate : null;
          let info = user.lName + " " + user.fName;
          info +=   " [ " + ( dtDate ? " " + dtDate + "  -  " : "") + dtTime + " ]";
          return info;
     };
     const screen = (width) => {
          return (width < 700) ? 'sm' : 'lg';
     }
     const visitorOnClickHandler = e => {
          console.log(e);
     }
     return (
          <>
               <ResponsiveBox
                    singleColumnScreen="sm"
                    screenByWidth={ screen }>
                    <Row ratio={1}></Row>
                    <Row ratio={1}></Row>
                    <Row ratio={1}></Row>
                    <Row ratio={1}></Row>
                    <Col ratio={1}></Col>
                    <Col ratio={1}></Col>
                    <Col ratio={1}></Col>
 
                    <Item>
                         <Location row={0}   col={0}   colspan={2}    screen="lg"    />
                         <Location row={0}   col={0}                  screen="sm"    />
                         <div className="item">
                         <div className='title' >Ingreso:</div>
                              <div>     <span className="caption">Guardia:</span> <span className="information">{ visitDetail.accessInfoIn }</span>       </div>
                         </div>
                    </Item>                  
                    <Item>
                         <Location row={1}   col={0}   colspan={3}    screen="lg"    />
                         <Location row={1}   col={0}                  screen="sm"    />
                         <div className="item">
                              <div className='title' >Vehículo:</div>
                              <div>     <span className="caption">Tipo:</span> <span className="information">{ visit.vehicle.type + " : " + visit.vehicle.color }</span>       </div>
                              <div>     <span className="caption">Marca:</span> <span className="information">{ visit.vehicle.brand.name }</span>                              </div>
                              <div>     <span className="caption">Placa:</span> <span className="information">{ visit.vehicle.plate.plateNumber }</span>                       </div>
                         </div>
                    </Item>
                    <Item>
                         <Location row={2}   col={0}   colspan={3}    screen="lg"    />
                         <Location row={2}   col={0}                  screen="sm"    />
                         <div className="item">
                              <div className='title' >Visitantes:</div>
                              { visit.visitors.map(v => (
                                   <div key={ v.id }>     <span className="caption">{ " - " }</span> <span className="visitor" onClick = { e => visitorOnClickHandler(v.id)  } >{ v.fullName }</span>                       </div>
                              ))}
                              <div style = {{ marginTop: "5px" }}>{ " " }</div>
                         </div>
                    </Item>
                    <Item>
                         <Location row={3}   col={0}   colspan={2}    screen="lg"    />
                         <Location row={3}   col={0}                  screen="sm"    />
                         <div className="item">
                              <div className='title' >Salida:</div>
                              <div> <span className="caption">Guardia:</span> <span className="information">{ visitDetail.accessInfoExit }</span>       </div>
                         </div>
                    </Item>
               </ResponsiveBox>
          </>
     )
}

export default VisitDetail
import React, { useState, useRef, useCallback } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Form, {
    Item,
    Label,
    ButtonItem, ButtonOptions,
    RequiredRule,
} from 'devextreme-react/form';
import LoadIndicator from 'devextreme-react/load-indicator';
import { myNotify } from '../../ui/myNotify';
import { useAuth } from '../../contexts/auth';
import './loginForm.scss';

export default function LoginForm() {
    const { signIn } = useAuth();
    const [ loading, setLoading ] = useState(false);
    const formData = useRef({ username: '', password: '' });
    const navigate = useNavigate();

    const onSubmit = useCallback(async (e) => {
        e.preventDefault();
        const { username, password } = formData.current;
        setLoading(true);        
        const result = await signIn(username, password);
        if (result.isOk) {
            navigate('/home');
            myNotify('Saludos ' + result.data.fName + " " + result.data.lName, 'success');
        } else {
            myNotify(result.message, 'error');
        }
        setLoading(false);
    }, [ signIn ]);
    
    return (
        <React.Fragment>
            <form className={'login-form'} onSubmit={ onSubmit }>
                <Form formData={formData.current} disabled={loading}>
                    <Item
                        dataField={'username'}
                        editorType={'dxTextBox'}
                        editorOptions={usernameEditorOptions}  >
                        <RequiredRule message="Usuario/Correo es necesario" />
                        <Label visible={ false } />
                    </Item>
                    <Item
                        dataField={'password'}
                        editorType={'dxTextBox'}
                        editorOptions={passwordEditorOptions} >
                        <RequiredRule message="Se requiere contraseña" />
                        <Label visible={ false } />
                    </Item>
                    <ButtonItem>
                        <ButtonOptions
                            width={'100%'}
                            type={'default'}
                            useSubmitBehavior={true} >
                            <span className="dx-button-text">
                            {
                                loading
                                ? <LoadIndicator width={'24px'} height={'24px'} visible={true} />
                                : 'Ingresar'
                            }
                            </span>
                        </ButtonOptions>
                    </ButtonItem>
                    <Item>
                        <div className={'link'}>
                            <Link to={'/reset-password'}>Olvidé Contraseña</Link>
                        </div>
                    </Item>
                </Form>
            </form>
        </React.Fragment>
    );
}
const isAdminEditorOptions = { text: 'Soy Administrador', elementAttr: { class: 'form-text' } };
const usernameEditorOptions   = { stylingMode: 'filled', placeholder: 'Usuario / Email',  mode: 'text' };
const passwordEditorOptions   = { stylingMode: 'filled', placeholder: 'Contraseña',       mode: 'password' };
import React, { useRef, useState } 
                            from 'react'
import DataGrid, {
    Column,
    Pager,
    Paging, 
    RequiredRule, 
} from 'devextreme-react/data-grid';
import { useNavigate }      from 'react-router-dom'
import { getStore } from '../../api/stores';
import { myNotify } from '../../ui/myNotify';
import DatagridHeader       from '../../components/datagrid-header/DatagridHeader';
import SectionForm from './SectionForm';

const Sections = () => {
    const navigate = useNavigate();
    const [editId, setEditId] = useState(null);
    const selectedId = useRef(null);
    
    const addHandler = () => { setEditId("new"); }
    const editHandler = () => {
        if (selectedId.current) {
            setEditId(selectedId.current);
            return;
        }
        myNotify('Seleccionar Cuenta para Editar', 'error');
    }
    const reportHandler = () => {
        if (selectedId.current)        {
            navigate('/sections/' + selectedId.current)
            return;
        }
        myNotify('Seleccionar Fraccionamiento para ver Estado', 'error');       
    }    
    const calculateHomeNumberCellValue = e => {
        if (e.homes?.length > 0)
            return e.homes[0].count;
    }
    return (
        <React.Fragment>
            <div className={'content-block'}>
                <div className={'dx-card responsive-paddings'} style={{ position: 'relative', boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)' }}>
                    <DatagridHeader title = { { banner: 'Fraccionamientos' } }      imageName='sections'
                                    navs =  { [ { icon: 'doc',  hint: 'Mostrar Reporte',         onClick: reportHandler }, 
                                                { icon: 'edit', hint: 'Editar Fraccionamiento',  onClick: editHandler },
                                                { icon: 'add',  hint: 'Agregar Fraccionamiento', onClick: addHandler }
                                            ] }
                    />
                    <DataGrid 
                        className={'dx-card wide-card'}
                        dataSource={ getStore('sections', '?showNumberOfHomes=1') }
                        columnMinWidth={50}
                        columnAutoWidth={true}
                        onFocusedRowChanged={ (e) => { selectedId.current = e.row.data.id; } }
                        onRowDblClick={ reportHandler }
                    >
                        <Paging defaultPageSize = { 10 } />
                        <Pager showPageSizeSelector={true} showInfo={true} />
                        <Column dataField={'id'}                    visible={false}  />
                        <Column dataField={'name'}                  caption={'Nombre'}          hidingPriority={3}  />
                        <Column                                     caption={'#Residencias'}   hidingPriority={1}  width={ 100 }   alignment={'center'} calculateCellValue={ calculateHomeNumberCellValue} />
                        <Column dataField={'emailMonthlyReport'}    caption={'Reporte'}         hidingPriority={2}  width={  75 }   dataType={'boolean'}/>
                    </DataGrid>
                    { editId &&         <SectionForm  id = { editId } onHidden={ () => setEditId(null) } />}
                </div>
            </div>
        </React.Fragment>
    )
}
export default Sections;
import React from 'react'
import { LoginForm } from '../../components';
import { SingleCard } from '../../layouts';
import { useAppInfo } from '../../contexts/appInfo'
import banner               from './images/banner.jpg'
import './login.scss'

const Login = () => {
    const { info } = useAppInfo();
    return (
        <React.Fragment>
            <div className={'content-block loginForm'} style = { { margin: "auto" }} >
                <div className={'dx-card responsive-paddings'} style={{ position: 'relative', boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)' }}>
                <h2>{ info?.appName } </h2>
                <h4>{ info?.client.name + (info?.client.city ? ' [' + info?.client.city + ']' : '' )} </h4>
                <img src={ banner } alt='banner' style = {{ width: "100%", height: 300, borderRadius: 5, border: '1px solid gray', boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)' }} />
                <h6>Administración y Control de Acceso</h6>
                <SingleCard title="Acceso a Portal">        
                    <LoginForm />         
                </SingleCard> 
                </div>
            </div>
        </React.Fragment>
    )
}

export default Login;
import React, { useRef, useState } 
                            from 'react'
import DataGrid, { Column, Pager, Paging, RequiredRule } 
                            from 'devextreme-react/data-grid';
import { useNavigate }      from 'react-router-dom'
import { getStore }         from '../../api/stores';
import { myNotify}          from '../../ui/myNotify';
import UserForm             from './UserForm';
import DatagridHeader       from '../../components/datagrid-header/DatagridHeader';

const Users = () => {
    const navigate = useNavigate();
    const [editId, setEditId] = useState(null);
    const selectedId = useRef(null);

    const addHandler = () => {  setEditId('new');    }
    const editHandler = () => {
        if (selectedId.current) {
            setEditId(selectedId.current);
            return;
        }
        myNotify('Seleccionar usuario para editar', 'error');
    }
    const reportHandler = () => {
        if (selectedId.current)        {
            navigate('/user/' + selectedId.current)
            return;
        }
        myNotify('Seleccionar usuario para ver reporte', 'error');       
    }
    return (
        <React.Fragment>
            <div className={'content-block'} >
                <div className={'dx-card responsive-paddings'} style={{ position: 'relative', boxShadow: '0 1px 2px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)' }}>
                    <DatagridHeader title = { { banner: 'Usuarios' } }      imageName='users'
                                    navs  = { [   { icon: 'doc',  hint: 'Mostrar Reporte', onClick: reportHandler }, 
                                                    { icon: 'edit', hint: 'Editar Usuario',  onClick: editHandler }, 
                                                    { icon: 'add',  hint: 'Agregar Usuario', onClick: addHandler }, 
                                                ] }
                    />   
                    <DataGrid 
                            id='datagrid'
                            keyExpr={ 'id' }
                            className={'dx-card wide-card'}
                            dataSource={ getStore('users') }
                            columnMinWidth={50}
                            columnAutoWidth={true}                       
                            onFocusedRowChanged={ (e) => { selectedId.current = e.row.data.id; } }
                            onRowDblClick={ reportHandler } 
                        >
                        <Paging defaultPageSize = { 10 } />
                        <Pager showPageSizeSelector={true} showInfo={true} />
                        <Column dataField={'id'}        visible={false}  />
                        <Column dataField={'access'}            caption={'Acceso'}      dataType={'boolean'}    width={75} />
                        <Column dataField={'username'}  />
                        <Column dataField={'fullName'}          caption={'Nombre'}   />
                        <Column dataField={'email'}             caption={'e-mail'}   />
                        <Column dataField={'phone'}             caption={'Telefono'}  />
                        <Column dataField={'isContractor'}      caption={'Contratante'} dataType={'boolean'}    width={100} />
                        <Column dataField={'isSystem'}          caption={'Sistema'}     dataType={'boolean'}    width={75} />
                        <Column dataField={'isAdmin'}           caption={'Admin'}       dataType={'boolean'}    width={75} />
                        <Column dataField={'isGuard'}           caption={'Guardia'}     dataType={'boolean'}    width={75} />
                    </DataGrid>
                    { editId &&         <UserForm  id = { editId } onHidden={ () => setEditId(null) } />}
                </div>
            </div>
        </React.Fragment>
    )
}

export default Users;